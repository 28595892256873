import { Backspace, ConfirmationNumber, Create, EmojiEvents, Inventory } from "@mui/icons-material";
import { lighten } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";

interface ActivityIconProps {
    action: string;
}

const ActivityIcon = ({action}:ActivityIconProps) => {
    const { Theme } = useContext(AppContext);

    const ActivityIconStyle = {
        my: "auto"
    }

    function GetIcon(){
        switch(action){
            case "cancelled":
                return <Backspace sx={{...ActivityIconStyle, color: Theme.palette.error.main}} />;
            case "winner":
                return <EmojiEvents sx={{...ActivityIconStyle, color: "gold"}} />;
            case "claimed":
                return <Inventory sx={{...ActivityIconStyle, color: Theme.palette.success.main}} />;
            case "line_purchased":
                return <ConfirmationNumber sx={{...ActivityIconStyle, color: lighten(Theme.palette.primary.main, 0.3)}} />;
            case "created":
                return <Create sx={{...ActivityIconStyle, color: "skyblue"}} />
            default:
                return <></>
        }
    }

    return (
        <GetIcon />
    );
}

export default ActivityIcon;