export function GetSocialWebsite(url: string | undefined){
    if(url){
        const urlLower = url.toLowerCase().split('.')[0];
    
        if(urlLower.includes("twitter")){
            return "twitter";
        } else if (urlLower.includes("discord")){
            return "discord";
        } else if (urlLower.includes("instagram")){
            return "instagram";
        } else if (urlLower.includes("tiktok")){
            return "tiktok";
        }
    }
    
    return "";
}

export function GetSocialMediaLabel(url:string | undefined){

    if(url){
        const urlLower = url.toLowerCase().split('.')[0];

        if(urlLower.includes("discord")){
            return "Server Invite Code"
        }

        return "Username";
    }

    return "Username";
}

export function GetSocialMediaUsername(url: string | undefined){
    if(url){
        const urlLowerSplit = url.toLowerCase().split('/');
        const username = urlLowerSplit.pop();

        if(username){
            if(username.includes("@")){
                return username.slice(1);
            }

            return username;
        }

        return "";
    }

    return "";
}