import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";

interface NotFoundProps {
    searchValue: string;
    type: string;
}

const NotFound = ({searchValue, type}: NotFoundProps) => {
    const navigate = useNavigate();
    const {user, isAuthenticated} = useContext(AppContext);

    function GetText(){
        if(type === "user"){
            return "This address has not created an account yet"
        } else if(type === "collection"){
            return "No raffles have been created for this collection. Create one today!"
        }

        return `Could not find a Raffle with ID: ${searchValue}`;
    }

    function GoBack(){
        window.scrollTo(0, 0);
        navigate(-1);
    }

    function GoToProfile(){
        window.scrollTo(0, 0);
        navigate(`/user/${user?.ethAddress}`);
    }

    function GetAction(){
        if(type === "user" || type === "raffle" || (type === "collection" && !isAuthenticated)){
            return <Button variant="contained" size="medium" onClick={() => GoBack()}>Go Back</Button>
        }

        return (
            <Button variant="contained" size="medium" onClick={() => GoToProfile()}>
                Create
            </Button>
        )
    }

    return (
        <Box minHeight="85vh" textAlign="center" alignItems="center" display="flex">
            <Box mx="auto">
                <Typography variant="h4" mb={2}>{GetText()}</Typography>
                <GetAction />
            </Box>
        </Box>
    );
}

export default NotFound;