import { useContext, useMemo } from "react";
import { AppContext } from "../context/AppContext";
import { Box, Grid, IconButton, Typography, styled } from "@mui/material";
import iRazLogo from "../images/iRaz_Primary.png";
import { Description, Instagram, Twitter } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";

const DividerLine = styled(Box)({
    width: "1px",
    backgroundColor: "white",
    height: "30px",
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "10px",
    marginRight: "10px",
});

const ButtonText = styled(Typography)({
    fontSize: 20,
    fontWeight: 800,
    fontStyle: 'italic',
    textTransform: 'uppercase',
    color: "white"
});

const Footer = () => {
    const { Theme } = useContext(AppContext);
    const navigate = useNavigate();

    const year = useMemo(() => {
        return new Date().getFullYear();
    }, [])

    function goHome(){
        window.scrollTo(0,0);
        navigate('/');
    }

    return(
        <Grid container sx={{backgroundColor: Theme.palette.primary.main}} p={2} px={{xs: 1, md: 6}} mt={12} justifyContent="center">
            <Grid item xs={12} textAlign="center">
                <img src={iRazLogo} alt="" height="60px" style={{marginTop: "auto", marginBottom: "auto", cursor: "pointer"}} onClick={goHome} />
                <Typography fontSize={{xs: 12, sm: 14, md: 14}} paddingLeft="15px" mt={2} color="white">The world's first decentralized raffle marketplace for collectibles and non-fungible tokens (NFTs) on the Ethereum blockchain.</Typography>
            </Grid>
            <Grid item xs={0} lg={7}></Grid>
            <Grid item xs={12} lg={6} display={{xs: "block", md: "flex"}} my={{xs: 2, md: "auto"}} justifyContent="center">
                <a href="https://twitter.com/iraz_raffles" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
                    <IconButton sx={{borderRadius: "10px", my: 1}}>
                        <Twitter sx={{fontSize: "20px", color: "white", mr: 1}} /> <ButtonText>Twitter</ButtonText>
                    </IconButton>
                </a>
                <DividerLine display={{xs: "none", md: "block"}} />
                <a href="https://discord.gg/PAtafcxdSp" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
                    <IconButton sx={{borderRadius: "10px", my: 1}}>
                        <FontAwesomeIcon icon={faDiscord} style={{fontSize: "20px", color: "white", marginRight: "10px"}} /><ButtonText>Discord</ButtonText>
                    </IconButton>
                </a>
                <DividerLine display={{xs: "none", md: "block"}} />
                <a href="https://www.instagram.com/iraz_raffles/" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
                    <IconButton sx={{borderRadius: "10px", my: 1}}>
                        <Instagram sx={{fontSize: "20px", color: "white", mr: 1}} /> <ButtonText>Instagram</ButtonText> 
                    </IconButton>
                </a>
                <DividerLine display={{xs: "none", md: "block"}} />
                <a href="https://docs.iraz.app/" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
                    <IconButton sx={{borderRadius: "10px", my: 1}}>
                        <Description sx={{fontSize: "20px", color: "white", mr: 1}} /> <ButtonText>DOCS</ButtonText>
                    </IconButton>
                </a>
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Typography fontSize={10} color="white">&copy; {year} - iRaz Industries</Typography>
            </Grid>
        </Grid>
    )
} 

export default Footer;