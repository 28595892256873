import { Activity } from "../interfaces/activity";

export function GetActivityName(activity:Activity){

    switch(activity.action){
        case "cancelled":
            return "Cancelled";
        case "winner":
            return "Winner";
        case "claimed":
            return "Claimed";
        case "line_purchased":
            return "Purchase Entry";
        case "created":
            return "Created";
    }
}