import { Box, Button } from "@mui/material";
import { CollectionFilter } from "../../interfaces/collectionFilter";
import { Raffle } from "../../interfaces/raffle";
import RaffleCard from "../RaffleCard";
import TabPanel from "../TabPanel";
import { ethers } from "ethers";
import { SortOrder, StatusFilter } from "../../interfaces/types";
import { SortRaffles } from "../../utils/sortItems";

interface RaffleTabProps {
    currentTab: number;
    raffles: Raffle[];
    index: number;
    removeFilter: (contract: string) => void;
    filters: CollectionFilter[];
    priceFilter: {min: number, max:number} | null;
    resetPriceFilter: VoidFunction;
    statusFilters: StatusFilter[];
    removeStatusFilter: (filter:StatusFilter) => void;
    userAddress: string;
    sortOrder: SortOrder;
    cardSize: number;
    searchQuery: string;
    openEnterModal: (item:Raffle) => void;
}

const RaffleTab = ({currentTab, raffles, index, removeFilter, filters, priceFilter, resetPriceFilter, statusFilters, removeStatusFilter, userAddress, sortOrder, cardSize = 330, searchQuery, openEnterModal}:RaffleTabProps) => {
    
    function StatusFilter(element:Raffle, index: number, array: Raffle[]){
        if(statusFilters.includes("completed")){
            if(element.entryCount === element.spots && element.winner) return true;
        }

        if(statusFilters.includes("pending")){
            if(element.status === "open") return true;
        }

        if(statusFilters.includes("lost")){
            if(element.winner === userAddress && element.winner === null) return true;
        }

        if(statusFilters.includes("won")){
            if(!element.winner) return false;
            return element.winner.toLowerCase() === userAddress.toLowerCase();
        }

        if(statusFilters.includes("cancelled")){
            if(element.status === "cancelled") return true;
            return false;
        }

        return false;
    }

    function SearchItems(element:Raffle, index: number, array: Raffle[]){
        if(element.collectionData.name.toLowerCase().includes(searchQuery.toLowerCase())) return true;
        if(element.name.toLowerCase().includes(searchQuery.toLowerCase())) return true;
        if(String(element.tokenId).includes(searchQuery.toLowerCase())) return true;
        return false;
    }

    function FilteredRaffles() {
        var pendingRaffles = raffles.filter(i => i.status === "open");
        var completedRaffles = raffles.filter(i => i.status === "claimed");
        var cancelledRaffles = raffles.filter(i => i.status === "cancelled");

        if(filters.length > 0){
            const raffleAddresses = filters.map(i => i.contract);

            pendingRaffles = pendingRaffles.filter((i) => raffleAddresses.includes(i.nftContract));
            completedRaffles = completedRaffles.filter((i) => raffleAddresses.includes(i.nftContract));
            cancelledRaffles = cancelledRaffles.filter((i) => raffleAddresses.includes(i.nftContract));
        }

        if(priceFilter){
            const min = ethers.parseEther(priceFilter.min.toString());
            const max = ethers.parseEther(priceFilter.max.toString());

            pendingRaffles = pendingRaffles.filter(i => ethers.parseUnits(i.entryPrice, "wei") <= max);
            pendingRaffles = pendingRaffles.filter(i => ethers.parseUnits(i.entryPrice, "wei") >= min);

            completedRaffles = completedRaffles.filter(i => ethers.parseUnits(i.entryPrice, "wei") <= max);
            completedRaffles = completedRaffles.filter(i => ethers.parseUnits(i.entryPrice, "wei") >= min);

            cancelledRaffles = cancelledRaffles.filter(i => ethers.parseUnits(i.entryPrice, "wei") <= max);
            cancelledRaffles = cancelledRaffles.filter(i => ethers.parseUnits(i.entryPrice, "wei") >= min);
        }

        if(statusFilters.length > 0){
            pendingRaffles = pendingRaffles.filter(StatusFilter);
            completedRaffles = completedRaffles.filter(StatusFilter);
            cancelledRaffles = cancelledRaffles.filter(StatusFilter);
        }

        var pendingSorted = SortRaffles(sortOrder, pendingRaffles);
        var completedSorted = SortRaffles(sortOrder, completedRaffles);
        var cancelledSorted = SortRaffles(sortOrder, cancelledRaffles);

        if(searchQuery !== ""){
            pendingSorted = pendingSorted.filter(SearchItems);
            completedSorted = completedSorted.filter(SearchItems);
            cancelledSorted = cancelledSorted.filter(SearchItems);
        }

        return {pendingSorted, completedSorted, cancelledSorted};
    }

    function StatusFilterName(filter:StatusFilter){
        switch(filter){
            case "completed":
                return "COMPLETED RAFFLES"
            case "lost":
                return "RAFFLES LOST"
            case "won":
                return "RAFFLES WON"
            case "pending":
                return "PENDING RAFFLES"
            case "cancelled":
                return "CANCELLED RAFFLES"
        }
    }

    const {pendingSorted, completedSorted, cancelledSorted} = FilteredRaffles();

    return (
        <TabPanel value={currentTab} index={index}>
            {(filters.length > 0 || priceFilter || statusFilters.length > 0) && (
            <Box marginLeft={2} marginRight={2} display="flex" flexWrap="wrap">
                {filters.map(i => {
                    return <Button variant="contained" color="error" onClick={() => removeFilter(i.contract)} sx={{marginRight: "5px"}}>
                        {i.name}
                    </Button>
                })}
                {priceFilter &&
                <Button variant="contained" color="error" sx={{marginRight: "5px"}} onClick={resetPriceFilter}>
                    {priceFilter.min} ETH - {priceFilter.max} ETH
                </Button>}
                {statusFilters.map(i => {
                    return <Button variant="contained" color="error" onClick={() => removeStatusFilter(i)} sx={{marginRight: "5px"}}>
                        {StatusFilterName(i)}
                    </Button>
                })}
            </Box>
            )}
            <Box display="flex" justifyContent={{xs: "center", md: "start"}} flexWrap={"wrap"}>
                {pendingSorted.map((raffle) => {
                    return (
                        <Box margin={{xs: 1, md: 2}} minWidth={cardSize} maxWidth={cardSize}>
                            <RaffleCard raffle={raffle} openEnterModal={openEnterModal} />
                        </Box>
                    );
                })}
                {completedSorted.map((raffle) => {
                    return (
                        <Box margin={{xs: 1, md: 2}} minWidth={cardSize} maxWidth={cardSize}>
                            <RaffleCard raffle={raffle} openEnterModal={openEnterModal} />
                        </Box>
                    );
                })}
                {cancelledSorted.map((raffle) => {
                    return (
                        <Box margin={{xs: 1, md: 2}} minWidth={cardSize} maxWidth={cardSize}>
                            <RaffleCard raffle={raffle} openEnterModal={openEnterModal} />
                        </Box>
                    );
                })}
            </Box>
        </TabPanel>
    );
}

export default RaffleTab;