import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled, TextField, Typography } from "@mui/material";
import { useState, useContext } from "react";
import { StatusFilter } from "../interfaces/types";
import StatusButtons from "./profile/StatusButtons";
import { AppContext } from "../context/AppContext";

const AccordionTitle = styled(Typography)({
    fontSize: "24px",
})

interface MobileCollectionFiltersProps {
    currentTab: number;
    applyPriceFilter: (min: number, max: number) => void;
    addStatusFilter: (status: StatusFilter) => void;
    resetStatusFilter: () => void;
}

const MobileCollectionFilters = ({
    currentTab, 
    applyPriceFilter,
    addStatusFilter,
    resetStatusFilter
}:MobileCollectionFiltersProps) => {
    const [priceType, setPriceType] = useState("ETH");
    const { Theme } = useContext(AppContext);

    const [minPrice, setMinPrice] = useState<number>(0);
    const [maxPrice, setMaxPrice] = useState<number>(10);

    const handlePriceChange = (event:SelectChangeEvent) => {
        setPriceType(event.target.value as string);
    }

    return (
        <>
        <Box padding={2} paddingTop={1} paddingBottom={1} sx={{background: Theme.palette.background.default}}>
            <Typography sx={{ textAlign: "left", fontSize: "28px" }}>
                Filters & Sorting
            </Typography>
        </Box>
        {currentTab === 0 &&
        <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <AccordionTitle>Price</AccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl sx={{ width: "70%" }}>
                    <InputLabel id="demo-simple-select-label">
                        Currency Type
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={priceType}
                        label="Currency Type"
                        onChange={handlePriceChange}
                    >
                        <MenuItem value="ETH">ETH</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="CAD">CAD</MenuItem>
                    </Select>
                </FormControl>
                <Box
                    display="flex"
                    justifyContent="space-around"
                    marginTop={2}
                    marginBottom={2}
                >
                    <FormControl sx={{ width: "40%" }}>
                        <TextField type="number" label="Min Price" value={minPrice} onChange={e => setMinPrice(Number(e.target.value))}></TextField>
                    </FormControl>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            marginTop: "auto",
                            marginBottom: "auto",
                        }}
                    >
                        to
                    </Typography>
                    <FormControl sx={{ width: "40%" }}>
                        <TextField type="number" label="Max Price" value={maxPrice} onChange={e => setMaxPrice(Number(e.target.value))}></TextField>
                    </FormControl>
                </Box>
                <Button size="medium" variant="contained" onClick={() => applyPriceFilter(minPrice, maxPrice)}>
                    Apply
                </Button>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <AccordionTitle>Status</AccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
                {currentTab === 0 && <StatusButtons resetStatusFilters={resetStatusFilter} addStatusFilter={addStatusFilter} buttons={["won", "lost", "completed", "cancelled"]} />}
            </AccordionDetails>
        </Accordion>
        </>}
        </>
    );
}

export default MobileCollectionFilters;