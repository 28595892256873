import { ethers } from "ethers";
import iRazAdmin from "../contracts/irazAdmin";

const iRazAdminAddress = process.env.REACT_APP_IRAZ_ADMIN_ADDRESS as string;

const IsAdmin = async (address: string) => {
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();


    const iRazAdminContract = new ethers.Contract(iRazAdminAddress, iRazAdmin.abi, signer);
    const result = await iRazAdminContract.IsAdmin(address);
    return result
}

const IsVerified = async (address: string) => {
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();


    const iRazAdminContract = new ethers.Contract(iRazAdminAddress, iRazAdmin.abi, signer);
    const result = await iRazAdminContract.IsVerified(address);
    return result
}

const AddToVerified = async (address: string) => {
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();


    const iRazAdminContract = new ethers.Contract(iRazAdminAddress, iRazAdmin.abi, signer);
    const result = await iRazAdminContract.AddToVerified(address);
    await result.wait();
    return result
}

const RevokeVerified = async (address: string) => {
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();


    const iRazAdminContract = new ethers.Contract(iRazAdminAddress, iRazAdmin.abi, signer);
    const result = await iRazAdminContract.RevokeVerified(address);
    await result.wait();
    return result
}

const iRazAdminAdapter = {
    IsAdmin,
    IsVerified,
    AddToVerified,
    RevokeVerified
};

export default iRazAdminAdapter;
