import { Box, Button, CircularProgress, Icon, IconButton, Modal, Typography } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import Styles from "../../themes/styles";
import MetaMaskFox from "../../images/MetaMask_Fox.svg";
import WalletConnect from "../../images/WalletConnect.svg";
import CoinbaseWallet from "../../images/coinbase-wallet.png";
import { AppContext } from "../../context/AppContext";
interface ConnectWalletProps {
    open: boolean;
    close: () => void;
}

const ConnectWallet = ({ open, close }:ConnectWalletProps) => {
    const { ModalStyle } = Styles();
    const { Theme, handleAuth, isAuthenticated } = useContext(AppContext);
    const [isPending, setIsPending] = useState<boolean>(false);

    const ButtonTheme = useMemo(() => {
        return {border: `1px solid ${Theme.palette.primary.main}`, backgroundColor: Theme.palette.primary.dark, borderRadius: "20px", width: "80%", marginLeft: "auto", marginRight: "auto", mb: 1};
    }, [Theme]);

    const loginWithProvider = (provider: string) => {
        setIsPending(true);
        try{
            handleAuth(provider, CloseModal, StopPending);
        } catch(error){
            setIsPending(false);
        }
    }

    function StopPending() {
        setIsPending(false);
    }

    function CloseModal() {
        setIsPending(false);
        close();
    }

    if (!isAuthenticated) {
        return (
            <Modal open={open} onClose={CloseModal}>
                <Box borderRadius="25px" sx={ModalStyle}>
                    <Box
                        textAlign="center"
                    >
                        <Typography
                            variant="h4"
                            sx={{ fontSize: "40px", fontWeight: 600 }}
                            marginBottom={2}
                        >
                            Connect Wallet
                        </Typography>
                    </Box>
                    <Box textAlign="center" py={2} display={isPending ? "none" : "block"}>
                        <IconButton sx={ButtonTheme}
                            onClick={() => loginWithProvider("metamask")}
                        >
                            <Icon sx={{fontSize: "90px"}}>
                                <img src={MetaMaskFox} style={{maxWidth: "300px", marginRight: "10px"}} alt="" />
                            </Icon>
                            <Typography variant="h5">MetaMask</Typography>
                        </IconButton>

                        <IconButton sx={{...ButtonTheme}}
                            onClick={() => loginWithProvider("walletconnect")}
                        >
                            <Icon sx={{fontSize: "90px"}}>
                                <img src={WalletConnect} style={{maxWidth: "300px", marginRight: "10px"}} alt="" />
                            </Icon>
                            <Typography variant="h5">WalletConnect</Typography>
                        </IconButton>

                        <IconButton sx={{...ButtonTheme}}
                            onClick={() => loginWithProvider("coinbase")}
                        >
                            <Icon sx={{fontSize: "90px", my: "auto"}}>
                                <img src={CoinbaseWallet} style={{maxWidth: "70px", marginRight: "40px"}} alt="" />
                            </Icon>
                            <Typography variant="h5">Coinbase</Typography>
                        </IconButton>

                        <Button size="medium" variant="contained" color="error" sx={{mt: 2}} onClick={CloseModal}>Cancel</Button>
                    </Box>
                    <Box textAlign="center" py={2} display={isPending ? "block" : "none"}>
                        <CircularProgress />
                    </Box>
                </Box>
            </Modal>
        );
    } else return <></>;
};

export default ConnectWallet;