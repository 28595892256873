import { EvmNft } from "@moralisweb3/evm-utils"
import { NFT } from "../interfaces/nft";

export function CheckIPFS(url: string){
    if(url.includes("ipfs://")){
        url = url.replace("ipfs://", "https://ipfs.io/ipfs/");
    }
    return url;
}

export const GetNftImage = (nft:EvmNft) => {
    var image = "https://i.pinimg.com/474x/18/45/61/1845613a68a35c3d138fbfa5d1bc3d7e.jpg";
    var meta = nft.metadata as any;
    if(meta && meta.image){
        image = meta.image;
    }
    image = CheckIPFS(image);
    return image;
}

export const ParseMeta = (nft:NFT) => {
    return JSON.parse(nft.metadata);
}