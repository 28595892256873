import { createTheme } from "@mui/material/styles";

export const LightTheme = createTheme({
    typography: {
        fontFamily: ["Montserrat", "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#4b006e",
            dark: "#01000c",
        },
        secondary: {
            main: "#ababab",
            dark: "#f7f7f7",
        },
        text: {
            primary: "#000000"
        },
        background: {
            default: "#ffffff"
        },
        mode: "light"
    },
    components: {
        MuiTypography: {
            defaultProps: {
                color: "black"
            }
        },
        MuiIconButton: {
            defaultProps: {
                color: "inherit"
            }
        },
        MuiIcon: {
            defaultProps: {
                color: "inherit"
            }
        },
        MuiSvgIcon: {
            defaultProps: {
                color: "secondary"
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 15,
                }
            }
        }
    },
});
