import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Clear, Search } from "@mui/icons-material";
import { SearchResult } from "../../interfaces/search";
import SearchApi from "../../api/search";
import SearchList from "./SearchList";

const SearchBar = () => {
    const { Theme } = useContext(AppContext);

    const [ focused, setFocused ] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const SearchItems = (query: string | null) => {
        if(query){
            setSearchQuery(query);
        } else {
            setSearchResults([]);
            setSearchQuery("");
        }
    }

    const hideList = () => {setFocused(false); SearchItems(null)};

    useEffect(() => {
        async function GetData(){
            const {searchData} = await SearchApi.search(searchQuery);

            setSearchResults(searchData);
        }

        if(searchQuery !== ""){
            const timer = setTimeout(() => {
                GetData();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [searchQuery]);

    return (
        <Box position="relative" width="100%">
            <TextField
                autoComplete="off"
                fullWidth
                placeholder="Collection Name, Username or Hash"
                sx={{'.MuiInputBase-root': {backgroundColor: Theme.palette.secondary.dark}, '.MuiAutocomplete-popupIndicator': {transform: "none"} }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchQuery.length === 0 ? <IconButton><Search /></IconButton> : <IconButton onClick={() => SearchItems(null)}><Clear /></IconButton>}
                        </InputAdornment>
                    ),
                }}
                focused={focused}
                onFocus={() => setFocused(true)}
                onBlur={() => setTimeout(() => {setFocused(false)}, 150)}
                onChange={(e) => SearchItems(e.currentTarget.value)}
                value={searchQuery}
            />
            <SearchList results={searchResults} focused={focused} hide={hideList} />
        </Box>
    );
}

export default SearchBar;