import { ListItem, darken } from "@mui/material";
import { SearchResult } from "../../interfaces/search";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

interface SearchListItemProps {
    item: SearchResult;
    hide: VoidFunction;
}

const SearchListItem = ({item, hide}: SearchListItemProps) => {
    const navigate = useNavigate();
    const { Theme, darkMode } = useContext(AppContext);

    const SelectResult = (searchItem: SearchResult) => {
        hide();
        window.scrollTo(0, 0);
        if(searchItem?.category === "Users"){
            navigate(`/user/${searchItem.address}`);
        } else if (searchItem?.category === "Collections") {
            navigate(`/collection/${searchItem.address}`);
        }
    }

    return (
        <ListItem 
            onClick={() => SelectResult(item)} 
            key={item.address}
            sx={{
                cursor: "pointer",
                ":hover": {backgroundColor: 'background.paper'}
            }}
        >
            <a 
                href={`/${item.category === "Users" ? "user" : "collection"}/${item.address}`}
                onClick={(e) => e.preventDefault()}
                style={{
                    textDecoration: "none",
                    color: darkMode ? "white": "black"
                }}
            >
                {item.name || item.address}
                </a>
        </ListItem>
    )
}

export default SearchListItem;