import { ethers } from "ethers";
import AuthApi from "../api/auth";

const connectToMetamask = async () => {
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
  
    const [accounts, chainId] = await Promise.all([
      provider.send('eth_requestAccounts', []),
      provider.send('eth_chainId', []),
    ]);
  
    const signer = await provider.getSigner();
    return { signer, chain: chainId, account: accounts[0] };
};

export const LoginWithMetamask = async() => {
  const { signer, chain, account } = await connectToMetamask();
  console.log("chain", chain)

  if (!account) {
      throw new Error('Connecting to chain failed, as no connected account was found');
  }
  if (!chain) {
      throw new Error('Connecting to chain failed, as no connected chain was found');
  }

  // Get message to sign from the auth api
  const { message } = await AuthApi.requestMessage(account, chain, "evm");

  const signature = await signer.signMessage(message);

  const { user } = await AuthApi.verifyMessage(message, signature, "evm");

  localStorage.setItem("SessionToken", user.sessionToken);

  return user;
}