import { Box, lighten, Typography } from "@mui/material";
import {useContext} from "react";
import { AppContext } from "../context/AppContext";

interface AttributeBubbleProps {
    name: string;
    value: string;
}

const AttributeBubble = ({name, value}:AttributeBubbleProps) => {
    const {Theme} = useContext(AppContext);

    function GetValue(){
        let valueLower = String(value).toLowerCase();

        if(valueLower === "false"){
            return "No";
        } else if(valueLower === "true"){
            return "Yes";
        }

        return value.toString();
    }

    return (
        <Box p={1} m={"5px"} textAlign="center" border={1} borderColor="gray" minWidth={"60px"} width={"fit-content"} borderRadius={2} sx={{
            backgroundColor: lighten(Theme.palette.primary.main, 0.5),
        }}>
            <Typography color={Theme.palette.primary.main} fontWeight={600} fontSize="12px" pb={0.6}>
                {name}
            </Typography>
            <Typography>
                {GetValue()}
            </Typography>
            <Typography display="none">
                &nbsp;
            </Typography>
        </Box>
    );
}

export default AttributeBubble;