import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Delete, ExpandMore, Favorite, FavoriteBorder, FormatListNumbered, List, Refresh, Share, TurnedInNot } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Container, Grid, IconButton, lighten, Link, Skeleton, Typography } from "@mui/material";
import { useEffect, useState, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EntryDataGrid from "../components/EntryDataGrid";
import { Raffle } from "../interfaces/raffle";
import RaffleApi from "../api/raffle";
import { TruncateEthAddress } from "../utils/formatAddress";
import { formatEther } from "ethers";
import {ToastContainer, toast} from "react-toastify";
//import Moralis from "moralis";
import EnterRaffle from "../components/modals/EnterRaffle";
import AttributeBubble from "../components/AttributeBubble";
import GetWinner from "../components/modals/GetWinner";
import ClaimRaffle from "../components/modals/ClaimRaffle";
import CancelRaffle from "../components/modals/CancelRaffle";
import { AppContext } from "../context/AppContext";
import { useCountdown } from "../hooks/useCountdown";
import { add } from "date-fns";
import useDocumentTitle from "../hooks/useDocumentTitle";
import NotFound from "../components/NotFound";
import { CheckIPFS } from "../utils/nftUtils";
import RaffleFloorPriceBadge from "../components/badges/RaffleFloorPriceBadge";

const ViewRaffle = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {Theme, darkMode, RefreshNotifications, openConnect, user, isAuthenticated} = useContext(AppContext);

    const [noRaffle, setNoRaffle] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const [raffle, setRaffle] = useState<Raffle | null>(null);
    const [metaData, setMetaData] = useState<any | null>();

    useDocumentTitle(raffle ? `${raffle.name} - Raffle | iRaz` : "Raffle | iRaz");

    const [isWatching, setWatching] = useState<boolean>(false);
    const [watchCount, setWatchCount] = useState<number>(0);

    const [showModal, setShowModal] = useState<boolean>(false);
    const OpenModal = () => setShowModal(true);
    const CloseModal = () => setShowModal(false);

    const [showWinnerModal, setShowWinnerModal] = useState<boolean>(false);
    const OpenWinnerModal = () => setShowWinnerModal(true);
    const CloseWinnerModal = () => setShowWinnerModal(false);

    const [showClaimModal, setShowClaimModal] = useState<boolean>(false);
    const OpenClaimModal = () => setShowClaimModal(true);
    const CloseClaimModal = () => setShowClaimModal(false);

    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const OpenCancelModal = () => setShowCancelModal(true);
    const CloseCancelModal = () => setShowCancelModal(false);

    const [days, hours, minutes, seconds] = useCountdown(raffle?.endTime || add(new Date(), {minutes: 30}).getTime() / 1000);

    const timeString = () => {
        let time = "";
        
        if(days > 0){
            time += days + (days <= 1 ? " day " : " days ");
        }
        time += hours + (hours <= 1 ? " hr " : " hrs ");
        time += minutes + (minutes <= 1 ? " min " : " mins ");
        time += seconds + (seconds <= 1 ? " sec" : " secs");

        if(days + hours + minutes + seconds <= 0){
            return "Raffle Has Ended"
        }
        return time;
    }

    async function WatchRaffle(){
        if(isAuthenticated){
            setWatching(oldValue => !oldValue);
            try{
                const result = await RaffleApi.watch(id as string);
                if(result){
                    setWatching(result.isWatching);
                    setWatchCount(result.watchCount);
                }
            } catch(error){
                setWatching(oldValue => !oldValue);
                toast.error("Error occurred when trying to watch the Raffle.");
            }
        } else {
            openConnect();
        }
    }

    async function getRaffle(id:string){
        try{
            const result = await RaffleApi.getById(id, user?.ethAddress);
            console.log(result)
            if(result.raffle?.nft){
                console.log(JSON.parse(result.raffle.nft.metadata));
                setMetaData(JSON.parse(result.raffle.nft.metadata));
            } else {
                setMetaData(null);
            }
            setWatchCount(result.raffle.watchCount);
            setWatching(result.watching);
            setRaffle(result.raffle);
        } catch(error:any){
            if(error.response.status === 404){
                setNoRaffle(true);
            }
            setRaffle(null);
        }
    }

    useEffect(() => {
        if(id){
            getRaffle(id as string);
        }
    }, [id, user]);

    useEffect(() => {
        if(raffle){
            setImageUrl(CheckIPFS(raffle.image));
        }
    }, [raffle])

    function OpenEnterModal(){
        if(isAuthenticated){
            OpenModal();
        } else {
            openConnect();
        }
    }

    function goToProfile(address: string){
        window.scrollTo(0, 0);
        navigate(`/user/${address}`);
    }

    function goToCollection(){
        window.scrollTo(0, 0);
        navigate(`/collection/${raffle?.nftContract}`);
    }

    function ShowPendingMessage(){
        toast.info("Your transaction is pending...");
    }

    async function RefreshMeta(){
        if(raffle){
            toast.info("This item has been queued for an update! Check back in a minute...");
            // cloud function to refresh nft metadata
            RaffleApi.refreshNft(id as string);
        }
    }

    function RefreshData(){
        RefreshNotifications();
        getRaffle(id as string);
    }

    const WatchButton = () => {
        if(isWatching){
            return <Favorite sx={{fontSize: "36px", color: "#f44336"}} />
        }
        return <FavoriteBorder sx={{fontSize: "36px", color: "#f44336"}} />
    }

    function CopyUrl(){
        const url = `${window.location.origin}/r/${id}`;
        navigator.clipboard.writeText(url);
        toast.success("Copied the shareable Raffle URL to your clipboard");
    }

    const RaffleStatusChip = useMemo(() => {
        if(raffle){
            let style = {
                fontSize: "22px",
                backgroundColor: darkMode ? "rgba(255, 255, 255, 0.31)" : "rgba(0, 0, 0, 0.31)"
            }
            let text = "Pending";

            if(raffle.status === "claimed"){
                style.backgroundColor = lighten(Theme.palette.primary.main, 0.5);
                text = "Claimed";
            } else if (raffle.status === "cancelled"){
                style.backgroundColor = lighten(Theme.palette.error.main, 0.3);
                text = "Cancelled";
            } else if (raffle.randomNum > -1){
                if(raffle.winner === user?.ethAddress){
                    style.backgroundColor = lighten(Theme.palette.primary.main, 0.5);
                    text = "Winner";
                } else {
                    style.backgroundColor = lighten(Theme.palette.error.main, 0.3);
                    text = "Lost";
                }
            } else if (raffle.endTime > 0 && Math.floor(Date.now() / 1000) > raffle.endTime){
                style.backgroundColor = lighten(Theme.palette.error.main, 0.3);
                text = "Ended";
            }

            return <Chip label={text} sx={style} />
        }
        return <></>;
    }, [raffle, user, Theme]);

    if(noRaffle){
        return <NotFound searchValue={id as string} type="raffle" />
    } else {

        return (
            <>
            <EnterRaffle open={showModal} close={CloseModal} callback={RefreshData} item={raffle}/>
            <GetWinner open={showWinnerModal} close={CloseWinnerModal} callback={RefreshData} item={raffle} />
            <ClaimRaffle open={showClaimModal} close={CloseClaimModal} callback={RefreshData} item={raffle} />
            <CancelRaffle open={showCancelModal} close={CloseCancelModal} callback={RefreshData} item={raffle} />
            <Container maxWidth="xl" sx={{my: 6, display: {xs: "block", md: "inline-flex"}}}>
                <Box width={{xs: "100%", md: "45%"}}>
                    <Box textAlign="left" border={1} borderRadius={4} borderColor="darkgray" mb={2}>
                        <Box p={2}>
                            <Box display="flex" justifyContent="space-between">
                                {raffle && raffle.collectionData.name ? <Typography onClick={goToCollection} sx={{fontSize: "20px", cursor: "pointer"}} color="grey">{raffle.collectionData.name}</Typography> : <Skeleton width="100%" height={30} /> }
                                {RaffleStatusChip}
                            </Box>
                            {raffle && raffle.name ? <Typography sx={{fontSize: "26px"}}>{raffle.name}</Typography> : <Skeleton width="100%" height={50} /> }
                        </Box>
                        <Box p={2} pt={0}>
                            <div style={{position: "relative"}}>
                                <RaffleFloorPriceBadge floorPrice={raffle?.collectionData.floorPrice} topLeft />
                                {raffle && imageUrl ? <img src={imageUrl} style={{width: "100%"}} alt="" /> : <Skeleton width="100%" height={600} /> }
                            </div>
                        </Box>
                    </Box>
                    <Box textAlign="left" border={1} borderColor="darkgray" borderRadius={4} py={2}>
                        <Box mx={2} display="flex" pb={1}>
                            <FormatListNumbered sx={{my: "auto"}}/><Typography fontSize="22px">&nbsp;Properties</Typography>
                        </Box>
                        {metaData && metaData.attributes &&
                        <Accordion sx={{borderTop: 1, borderColor: "darkgray", '&.Mui-expanded': {margin: "0px!important"}}} defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}>
                                <TurnedInNot sx={{my: "auto"}}/><Typography fontSize={20}> Traits</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{display: "flex", flexWrap: "wrap"}}>
                                {metaData && metaData.attributes &&
                                metaData.attributes.map((i:any) => {
                                    return <AttributeBubble name={i.trait_type} value={i.value as string} />
                                })}
                            </AccordionDetails>
                        </Accordion>
                        }
                        <Accordion sx={{borderTop: 1, borderBottom: 0, borderColor: "darkgray", '&.Mui-expanded': {margin: "0px!important"}}}>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}>
                                <List sx={{my: "auto"}}/><Typography fontSize={20}> Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Box display="flex" justifyContent="space-between">
                                <Typography sx={{fontWeight: 600}}>TokenID</Typography>
                                {raffle && raffle.tokenId ? <Typography>{raffle.tokenId}</Typography> : <Skeleton width={60} /> }
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Typography sx={{fontWeight: 600}}>Contract</Typography>
                                {raffle && raffle.nftContract ? <Typography>{TruncateEthAddress(raffle.nftContract)}</Typography> : <Skeleton width={60} /> }
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Typography sx={{fontWeight: 600}}>Blockchain</Typography>
                                <Typography>Ethereum</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Typography sx={{fontWeight: 600}}>Token Standard</Typography>
                                <Typography>ERC-721</Typography>
                            </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
                <Box width={{xs: "100%", md: "50%"}} mx={{xs: 0, md: 2}} mt={{xs: 2, md: 0}} border={1} borderColor="darkgray" borderRadius={4} height="fit-content">
                    <Grid container borderBottom={1} borderColor="darkgrey">
                        <Grid item xs={4} md={8} my="auto" textAlign="left">
                            {raffle && raffle.entryCount < (raffle.spots - (raffle.spots % 2)) / 2 && raffle.lister === user?.ethAddress &&
                            <IconButton sx={{ml: 1}} onClick={OpenCancelModal}>
                                <Delete color="error" />
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={8} md={4}>
                            <Box textAlign="right">
                                <IconButton onClick={WatchRaffle}>
                                    <Typography sx={{fontSize: "20px"}} fontWeight={600} pr={0.5}>{watchCount}</Typography><WatchButton />
                                </IconButton>
                                <IconButton onClick={RefreshMeta}>
                                    <Refresh sx={{fontSize: "36px"}} />
                                </IconButton>
                                <IconButton>
                                    <Share sx={{fontSize: "36px"}} onClick={CopyUrl} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="space-evenly" mt={5}>
                        <Box>
                            <Typography sx={{fontSize: "32px", fontWeight: 600}}>Spots</Typography>
                            {raffle ? <Typography sx={{fontSize: "26px"}}>{raffle.entryCount}/{raffle.spots}</Typography> : <Skeleton height={40} /> }
                        </Box>
                        <Box>
                            <Typography sx={{fontSize: "32px", fontWeight: 600}}>Price</Typography>
                            {raffle && raffle.entryPrice ? <Typography sx={{fontSize: "26px"}}>{formatEther(raffle.entryPrice)} <FontAwesomeIcon icon={faEthereum} /></Typography> : <Skeleton height={40} /> }
                        </Box>
                    </Box>
                    {raffle && (raffle.status === "open" && raffle.entryCount < raffle.spots && raffle.endTime !== 0) &&
                    <Box textAlign="center" my="auto" mt={2}>
                        {raffle ?
                        <Chip label={timeString()} color="primary" sx={{fontSize: "22px", fontWeight: 600, padding: "10px"}} />
                        : <Skeleton />}
                    </Box>
                    }
                    <Box mx={6} pt={3}>
                        {raffle && raffle.username ?
                        <Typography sx={{fontSize: "20px"}}>
                            Listed by: <Link sx={{color: lighten(Theme.palette.primary.main, 0.3), cursor: "pointer", textDecoration: "none"}} onClick={() => goToProfile(raffle.lister)}>{raffle.username}</Link>
                        </Typography>
                        : <Skeleton sx={{mx: "auto"}} width={100}/> }
                    </Box>
                    {raffle && !raffle.winner && raffle.status === "open" && (raffle.endTime > 0 ? Math.floor(Date.now() / 1000) < raffle.endTime : true) &&
                    <Box>
                        <Button
                            variant="contained"
                            size="medium"
                            sx={{ fontSize: "20px" }}
                            onClick={OpenEnterModal}
                            disabled={raffle.entryCount >= raffle.spots}
                        >
                            {raffle.entryCount >= raffle.spots ? "All spots full" : "Enter Raffle"}
                        </Button><br />
                        {raffle.lister === user?.ethAddress &&
                        <>
                        <Button
                            variant="contained"
                            size="medium"
                            sx={{ fontSize: "20px", mt: 1 }}
                            onClick={OpenWinnerModal}
                            disabled={raffle.entryCount < raffle.spots}
                        >
                            SPIN FOR THE WINNER
                        </Button>
                        <br />
                        </>
                        }
                    </Box>
                    }
                    {raffle && raffle.winner &&
                    <Box>
                        <Typography 
                            onClick={() => goToProfile(raffle.winner)} 
                            fontWeight={600}
                            fontSize={28} 
                            color={lighten(Theme.palette.primary.main, 0.3)}
                            sx={{cursor: "pointer"}}
                        >
                            Winner: {raffle.winnerName || TruncateEthAddress(raffle.winner)}
                        </Typography>
                        {raffle.winner === user?.ethAddress &&
                        <Button
                            variant="contained"
                            size="medium"
                            sx={{ fontSize: "20px", mt: 2 }}
                            onClick={OpenClaimModal}
                            disabled={raffle.status === "claimed"}
                        >
                            {raffle.status === "claimed" ? "Claimed" : "Claim Raffle"}
                        </Button>}
                    </Box>
                    }
                    {raffle && raffle.status === "cancelled" && 
                    <Box>
                        <Typography
                            fontWeight={600}
                            fontSize={28}
                            color={Theme.palette.error.main}
                        >
                            Raffle Cancelled
                        </Typography>
                    </Box>}
                    <Typography mt={3} textAlign="left" fontSize="28px" width={{xs: "90%", md: "80%"}} mx="auto">Entries</Typography>
                    <Box sx={{ width: {xs: "90%", md: "80%"}, border: 1, borderColor: "darkgrey", mx: "auto"}} mb={7} flexGrow={1} height="400px" maxHeight="400px">
                        {raffle && raffle.entries ? <EntryDataGrid rows={raffle.entries} /> : <Skeleton height={100}/> }
                        {raffle && raffle.winner && <Typography fontSize={{xs: "0.47em", md: 10}} mt={1}>Nonce: {raffle.nonce}</Typography>}
                    </Box>                
                </Box>
            </Container>
            </>
        );
    }
}

export default ViewRaffle;