import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { NFTCollection } from "../interfaces/collection";
import { StatusFilter } from "../interfaces/types";
import StatusButtons from "./profile/StatusButtons";

const AccordionTitle = styled(Typography)({
    fontSize: "18px",
})

interface ProfileFilterProps {
    currentTab: number;
    nftCollections: NFTCollection[];
    rafflesEnteredCollections: NFTCollection[];
    rafflesCreatedCollections: NFTCollection[];
    rafflesWatchingCollections: NFTCollection[];
    addNftFilter: (contract: string, name: string) => void;
    addCreatedFilter: (contract: string, name: string) => void;
    addEnteredFilter: (contract: string, name: string) => void;
    addWatchingFilter: (contract: string, name: string) => void;
    applyPriceFilter: (min: number, max: number) => void;
    addCreatedStatusFilter: (status: StatusFilter) => void;
    resetCreatedStatusFilter: () => void;
    addEnteredStatusFilter: (status: StatusFilter) => void;
    resetEnteredStatusFilter: () => void;
    addWatchingStatusFilter: (status: StatusFilter) => void;
    resetWatchingStatusFilter: () => void;
}

const ProfileFilters = ({
    currentTab, 
    nftCollections, 
    rafflesCreatedCollections, 
    rafflesEnteredCollections, 
    rafflesWatchingCollections,
    addNftFilter, 
    addCreatedFilter,
    addEnteredFilter, 
    addWatchingFilter,
    applyPriceFilter,
    addCreatedStatusFilter,
    resetCreatedStatusFilter,
    addEnteredStatusFilter,
    resetEnteredStatusFilter,
    addWatchingStatusFilter,
    resetWatchingStatusFilter
}:ProfileFilterProps) => {
    const [priceType, setPriceType] = useState("ETH");

    const [minPrice, setMinPrice] = useState<number>(0);
    const [maxPrice, setMaxPrice] = useState<number>(10);

    const handlePriceChange = (event:SelectChangeEvent) => {
        setPriceType(event.target.value as string);
    }

    function CollectionFilters() {
        if(currentTab === 0){
            return (
            <>
            {nftCollections.map((i) => {
                return (
                    <>
                    <Button
                        size="medium"
                        variant="contained"
                        sx={{ marginBottom: "20px" }}
                        onClick={() => addNftFilter(i.contract, i.name)}
                    >
                        {i.name}
                    </Button><br/>
                    </>
                );
            })}
            </>);
        } else if(currentTab === 1) {
            return (
                <>
                    {rafflesCreatedCollections.map((i) => {
                        return (
                            <>
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{ marginBottom: "20px" }}
                                onClick={() => addCreatedFilter(i.contract, i.name)}
                            >
                                {i.name}
                            </Button><br/>
                            </>
                        );
                    })}
                    <br/>
                </>
            );
        } else if(currentTab === 2){
            return (
                <>
                    {rafflesEnteredCollections.map((i) => {
                        return (
                            <>
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{ marginBottom: "20px" }}
                                onClick={() => addEnteredFilter(i.contract, i.name)}
                            >
                                {i.name}
                            </Button><br/>
                            </>
                        );
                    })}
                    <br/>
                </>
            );
        } else {
            return (
                <>
                    {rafflesWatchingCollections.map((i) => {
                        return (
                            <>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ marginBottom: "20px" }}
                                    onClick={() =>
                                        addWatchingFilter(i.contract, i.name)
                                    }
                                >
                                    {i.name}
                                </Button>
                                <br />
                            </>
                        );
                    })}
                    <br />
                </>
            );
        }
    }

    return (
        <>
        <Box padding={2} paddingTop={1} paddingBottom={1}>
            <Typography sx={{ textAlign: "left", fontSize: "22px" }}>
                Filters
            </Typography>
        </Box>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <AccordionTitle>Collection</AccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
                <CollectionFilters />
            </AccordionDetails>
        </Accordion>
        {currentTab > 0 &&
        <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <AccordionTitle>Price</AccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl sx={{ width: "70%" }}>
                    <InputLabel id="demo-simple-select-label">
                        Currency Type
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={priceType}
                        label="Currency Type"
                        onChange={handlePriceChange}
                    >
                        <MenuItem value="ETH">ETH</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="CAD">CAD</MenuItem>
                    </Select>
                </FormControl>
                <Box
                    display="flex"
                    justifyContent="space-around"
                    marginTop={2}
                    marginBottom={2}
                >
                    <FormControl sx={{ width: "40%" }}>
                        <TextField type="number" label="Min Price" value={minPrice} onChange={e => setMinPrice(Number(e.target.value))}></TextField>
                    </FormControl>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            marginTop: "auto",
                            marginBottom: "auto",
                        }}
                    >
                        to
                    </Typography>
                    <FormControl sx={{ width: "40%" }}>
                        <TextField type="number" label="Max Price" value={maxPrice} onChange={e => setMaxPrice(Number(e.target.value))}></TextField>
                    </FormControl>
                </Box>
                <Button size="medium" variant="contained" onClick={() => applyPriceFilter(minPrice, maxPrice)}>
                    Apply
                </Button>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <AccordionTitle>Status</AccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
                {currentTab === 1 && <StatusButtons resetStatusFilters={resetCreatedStatusFilter} addStatusFilter={addCreatedStatusFilter} buttons={["completed", "cancelled"]} />}
                {currentTab === 2 && <StatusButtons resetStatusFilters={resetEnteredStatusFilter} addStatusFilter={addEnteredStatusFilter} buttons={["won", "lost", "cancelled"]} />}
                {currentTab === 3 && <StatusButtons resetStatusFilters={resetWatchingStatusFilter} addStatusFilter={addWatchingStatusFilter} buttons={["won", "lost", "cancelled"]} />}
            </AccordionDetails>
        </Accordion>
        </>}
        </>
    );
}

export default ProfileFilters;