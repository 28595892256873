import { Inbox } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { LinePurchased } from "../interfaces/linePurchased";
import { TruncateEthAddress } from "../utils/formatAddress";

interface EntryDataGridProps {
    rows: LinePurchased[]
}

const EntryDataGrid = ({rows}: EntryDataGridProps) => {
    const navigate = useNavigate();
    
    const columns: GridColDef[] = [
        {
            field: "username",
            headerName: "Username",
            flex: 1,
            renderCell: (params) => (
                <Typography>{params.row.username}</Typography>
            )
        },
        {
            field: "userEthAddress",
            headerName: "Address",
            flex: 1,
            renderCell: (params) => (
                <Typography>{TruncateEthAddress(params.row.userEthAddress)}</Typography>
            )
        },
        {
            field: "count",
            headerName: "Count",
            width: 80,
            renderCell: (params) => (
                <Box width={"100%"}>
                    <Typography textAlign="center">{params.row.count}</Typography>
                </Box>
            )
        },
        /*{
            field: "transactionUrl",
            headerName: "Transaction",
            flex: 1,
        },*/
    ];

    function CustomNoRowsOverlay(){
        return(
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                <Inbox sx={{fontSize: "70px", opacity: 0.2}}/>
                <Typography>No Entries Yet</Typography>
            </Box>
        )
    }

    function GoToProfile(address: string){
        window.scrollTo(0, 0);
        navigate(`/user/${address}`);
    }
    
    return (
        <DataGrid
            columns={columns}
            rows={rows}
            autoPageSize
            sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                    fontSize: "18px",
                    fontWeight: 600,
                },
            }}
            slots={{
                noRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={(params, event, details) => {
                if(params.row.userEthAddress){
                    GoToProfile(params.row.userEthAddress);
                }
            }}
            disableColumnSelector
        />
    );
}

export default EntryDataGrid;