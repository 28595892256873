import { StatusFilter } from "../../interfaces/types";
import {Button} from "@mui/material";

interface StatusButtonProps {
    addStatusFilter: (status:StatusFilter) => void;
    resetStatusFilters: () => void;
    buttons: StatusFilter[];
}

const StatusButtons = ({addStatusFilter, resetStatusFilters, buttons}:StatusButtonProps) => {
    return(
    <>
    {buttons.includes("won") &&
    <><Button
        size="medium"
        variant="contained"
        sx={{ marginBottom: "20px" }}
        onClick={() => addStatusFilter("won")}
    >
        Raffles Won
    </Button><br/></>}
    {buttons.includes("lost") &&
    <><Button
        size="medium"
        variant="contained"
        sx={{ marginBottom: "20px" }}
        onClick={() => addStatusFilter("lost")}
    >
        Raffles Lost
    </Button><br/></>}
    {buttons.includes("completed") &&
    <>
    <Button
        size="medium"
        variant="contained"
        sx={{ marginBottom: "20px" }}
        onClick={() => addStatusFilter("completed")}
    >
        Completed Raffles
    </Button><br/></>}
    {buttons.includes("cancelled") &&
    <><Button
        size="medium"
        variant="contained"
        sx={{ marginBottom: "20px" }}
        onClick={() => addStatusFilter("cancelled")}
    >
        Cancelled Raffles
    </Button><br/></>}
    <Button
        size="medium"
        variant="contained"
        sx={{ marginBottom: "20px" }}
        onClick={() => addStatusFilter("pending")}
    >
        Pending Raffles
    </Button><br/>
    <Button
        size="medium"
        variant="contained"
        sx={{ marginBottom: "20px" }}
        onClick={resetStatusFilters}
    >
        All Raffles
    </Button>
    </>
    );
}

export default StatusButtons;