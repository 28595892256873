import { Badge, BadgeProps, Box, Typography, lighten, styled } from "@mui/material";
import { Notification } from "../interfaces/notification";
import ActivityIcon from "./ActivityIcon";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";

interface NotificationItemProps {
    notification: Notification;
}

const NotificationItem = ({notification}:NotificationItemProps) => {
    const { Theme, user } = useContext(AppContext);

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
          left: 20,
          
        },
      }));

    function GetLineCount(count: number, allSpotsFilled: Boolean){
        if(allSpotsFilled){
            return `the last entries`
        }
        if(count > 1){
            return `${count} entries`
        }
        return `an entry`;
    }

    function GetText(){
        switch(notification.activity?.action){
            case "line_purchased":
                return ` purchased ${GetLineCount(notification.activity.quantity || 0, notification.allSpotsFilled)} to Raffle #${notification.activity.raffleId}`;
            case "cancelled":
                return ` cancelled Raffle #${notification.activity.raffleId}`;
            case "claimed":
                return ` claimed ${notification.activity.nft.name} #${notification.activity.nft.tokenId}`;
            case "created":
                return ` created Raffle for ${notification.activity.nft.name} #${notification.activity.nft.tokenId}`;
            case "winner":
                return ` won Raffle #${notification.activity.raffleId}!`;
            default:
                return "";
        }
    }

    const usernameStyle = {
        color: lighten(Theme.palette.primary.main, 0.6),
        pl: 1,
        pr: "5px"
    }

    function GetUsername(username: string){
        if(user?.username.toLowerCase() === username.toLowerCase()){
            return "You"
        }

        return username;
    }

    function Username(){
        let text = ""
        switch(notification.activity?.action){
            case "line_purchased":
                if(notification.activity.fromUser){
                    text = GetUsername(notification.activity.fromUser?.username);
                }
                break;
            case "cancelled":
                if(notification.activity.fromUser){
                    text = GetUsername(notification.activity.fromUser?.username);
                }
                break;
            case "claimed":
                if(notification.activity.toUser){
                    text = GetUsername(notification.activity.toUser?.username);
                }
                break;
            case "created":
                if(notification.activity.fromUser){
                    text = GetUsername(notification.activity.fromUser?.username);
                }
                break;
            case "winner":
                if(notification.activity.toUser){
                    text = GetUsername(notification.activity.toUser?.username);
                }
                break; 
            default:
                text = "";
                break;
        }

        return <Typography sx={usernameStyle}>{text}</Typography>
    }

    if(notification.activity){
        return (
            notification.hasUserSeen ?
            <Box display="flex" width="100%" borderTop={1} borderBottom={1} borderColor="lightgray" p={1} position="relative">
                <ActivityIcon action={notification.activity.action} />
                <Username />
                <Typography>{GetText()}</Typography>
            </Box>:
            <StyledBadge badgeContent="New" color="error" anchorOrigin={{vertical: "top", horizontal: "left"}}>
                <Box display="flex" borderTop={1} borderBottom={1} borderColor="lightgray" p={1} position="relative">
                    <ActivityIcon action={notification.activity.action} />
                    <Username />
                    <Typography>{GetText()}</Typography>
                </Box>
            </StyledBadge>
        );
    } else {
        return <></>
    }
    
}

export default NotificationItem;