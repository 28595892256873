import { Box, Typography, lighten, styled } from "@mui/material";
import { DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { GetActivityName } from "../../utils/activityHelper";
import { CheckIPFS, ParseMeta } from "../../utils/nftUtils";
import { formatEther } from "ethers";
import { formatDistance } from "date-fns";
import { Activity } from "../../interfaces/activity";
import {useContext} from "react";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { GetTransactionUrl } from "../../utils/getTransactionUrl";
import ActivityIcon from "../ActivityIcon";
import {useMemo} from "react";

interface ActivityDataGridProps {
    index: number;
    currentTab: number;
    activity: Activity[];
    title: string;
}

const ActivityDataGrid = ({index, currentTab, activity, title}:ActivityDataGridProps) => {
    const { Theme, breakpoint } = useContext(AppContext);
    const navigate = useNavigate();

    const StyledName = styled(Typography)({
        color: lighten(Theme.palette.primary.main, 0.3),
        fontWeight: 600,
        cursor: "pointer"
    });

    function goToProfile(address:string){
        window.scrollTo(0, 0);
        navigate(`/user/${address}`);
    }

    function goToRaffle(id: number){
        window.scrollTo(0, 0);
        navigate(`/raffle/${id}`);
    }

    function goToTransaction(txHash: string){
        var url = GetTransactionUrl(txHash);
        window.open(url, "_blank");
    }

    const columns: GridColDef[] = useMemo(() => {
        return [
            { 
                field: 'action', 
                headerName: 'Action', 
                renderHeader: (params: GridColumnHeaderParams) => <Typography fontSize={20} fontWeight={600}>{params.colDef.headerName}</Typography>,
                flex: breakpoint === "xs" || breakpoint === "sm" ? 0 : 1,
                minWidth: breakpoint === "xs" || breakpoint === "sm" ? 200 : undefined,
                valueGetter: (params: GridValueGetterParams) => GetActivityName(params.row),
                renderCell: (params: GridRenderCellParams) => 
                <Box display="flex">
                    <ActivityIcon action={params.row.action} />
                    <Typography ml="8px" fontWeight={600}>{GetActivityName(params.row)}</Typography>
                </Box>
                
            },
            {
                field: 'nft',
                headerName: 'Raffle',
                renderHeader: (params: GridColumnHeaderParams) => <Typography fontSize={20} fontWeight={600}>{params.colDef.headerName}</Typography>,
                flex: breakpoint === "xs" || breakpoint === "sm" ? 0 : 1,
                minWidth: breakpoint === "xs" || breakpoint === "sm" ? 250 : undefined,
                renderCell: (params: GridRenderCellParams) => {
                    const meta = ParseMeta(params.row.nft);
                    return (
                    <Box display="flex" sx={{cursor: "pointer"}} onClick={() => goToRaffle(params.row.raffleId)} width="100%">
                        <img src={CheckIPFS(meta.image)} width="60px" height="60px" style={{borderRadius: "10px"}} alt=""/>
                        <Box p={1} my="auto" textAlign="left">
                            <Typography fontSize={16}>{meta.name}</Typography>
                            <Typography fontSize={12}>{params.row.nft.name}</Typography>
                        </Box>
                    </Box>
                    )
                }
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                renderHeader: (params: GridColumnHeaderParams) => <Typography fontSize={20} fontWeight={600}>{params.colDef.headerName}</Typography>,
                flex: 0,
                minWidth: 120,
                renderCell: (params: GridRenderCellParams) => {
                    return <Typography>{params.row.quantity && params.row.quantity ? params.row.quantity : "--"}</Typography>
                }
                
            },
            {
                field: 'price',
                headerName: 'Price',
                renderHeader: (params: GridColumnHeaderParams) => <Typography fontSize={20} fontWeight={600}>{params.colDef.headerName}</Typography>,
                flex: 0,
                renderCell: (params: GridRenderCellParams) =>
                <Typography>{params.row.price ? formatEther(params.row.price) : "--"} {params.row.price ? <FontAwesomeIcon icon={faEthereum} />: ""} </Typography>
            },
            {
                field: 'fromUser',
                headerName: 'From',
                renderHeader: (params: GridColumnHeaderParams) => <Typography fontSize={20} fontWeight={600}>{params.colDef.headerName}</Typography>,
                flex: breakpoint === "xs" || breakpoint === "sm" ? 0 : 1,
                valueGetter: (params: GridValueGetterParams) =>
                `${params.row.fromUser ? params.row.fromUser.ethAddress : null}`,
                renderCell: (params: GridRenderCellParams) => {
                    if(params.row.fromUser){
                        return <StyledName onClick={() => goToProfile(params.row.from)}>{params.row.fromUser.username}</StyledName>
                    }
                    return <Typography>--</Typography>
                }
            },
            {
                field: 'toUser',
                headerName: 'To',
                renderHeader: (params: GridColumnHeaderParams) => <Typography fontSize={20} fontWeight={600}>{params.colDef.headerName}</Typography>,
                flex: breakpoint === "xs" || breakpoint === "sm" ? 0 : 1,
                valueGetter: (params: GridValueGetterParams) =>
                `${params.row.toUser ? params.row.toUser.ethAddress : null}`,
                renderCell: (params: GridRenderCellParams) => {
                    if(params.row.toUser){
                        return <StyledName onClick={() => goToProfile(params.row.to)}>{params.row.toUser.username}</StyledName>
                    }
                    return <Typography>--</Typography>
                }
            },
            {
                field: 'actionAt',
                headerName: 'Time',
                renderHeader: (params: GridColumnHeaderParams) => <Typography fontSize={20} fontWeight={600}>{params.colDef.headerName}</Typography>,
                flex: breakpoint === "xs" || breakpoint === "sm" ? 0 : 1,
                minWidth: breakpoint === "xs" || breakpoint === "sm" ? 200 : undefined,
                renderCell: (params: GridRenderCellParams) =>
                <Box display="flex" sx={{cursor: "pointer"}} onClick={() => goToTransaction(params.row.txHash)}>
                    <Typography mr="8px" fontWeight={600}>{formatDistance(new Date(params.row.actionAt), Date.now())} ago</Typography>
                    <Launch sx={{my: "auto", color: lighten(Theme.palette.primary.main, 0.3)}}/>
                </Box>
            },
          ];
    }, [breakpoint, Theme, activity]);
    
    if(index === currentTab){
        return (
        <Box sx={{ width: '80%', mx: "auto", mb: 10 }}>
            <Typography variant="h5" mt={3} mb={4}>{title}</Typography>
            <DataGrid
                rows={activity}
                columns={columns}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 50,
                    },
                    },
                    sorting: {
                        sortModel: [{ field: 'actionAt', sort: 'desc' }],
                    }
                }}
                pageSizeOptions={[25, 50, 100, 250]}
                disableRowSelectionOnClick
                disableColumnSelector
                rowHeight={80}
                autoHeight
            />
      </Box>);
    }

    return (
        <></>
    );
}

export default ActivityDataGrid;