import { SiteStats } from "../interfaces/siteStats";
import { axios } from "./configs/axios";

const ENDPOINT = "/stats";

const getStats = async () => {
    const { data } = await axios.get(`${ENDPOINT}`);

    return {
        data: data as SiteStats
    };
};

const StatsApi = {
    getStats
};

export default StatsApi;
