import Axios, { InternalAxiosRequestConfig } from "axios";

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
    const token = localStorage.getItem("SessionToken");

    config.headers.setContentType("application/json");
    config.headers.setAuthorization("Bearer " + token);

    // assertion that env variable exists was already done outside the function
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // config.headers.authorization = process.env.NEXT_PUBLIC_API_TOKEN!;
    return config;
}

export const authApi = Axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("SessionToken")}`,
    },
})

export const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("SessionToken")}`,
    },
});

axios.interceptors.request.use(authRequestInterceptor);
authApi.interceptors.request.use(authRequestInterceptor);
