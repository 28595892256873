import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState, useContext, useMemo } from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import {Autoplay} from "swiper";
import { Raffle } from "../interfaces/raffle";
import RaffleApi from "../api/raffle";
import RaffleCard from "../components/RaffleCard";
import { Collection } from "../interfaces/collection";
import CollectionApi from "../api/collection";
import CollectionCard from "../components/CollectionCard";
import { useNavigate } from "react-router-dom";
import EnterRaffle from "../components/modals/EnterRaffle";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { AppContext } from "../context/AppContext";

const Home = () => {
    const navigate = useNavigate();
    const [recentRaffles, setRecentRaffles] = useState<Raffle[]>([]);
    const [popularCollections, setPopularCollections] = useState<Collection[]>([]);

    const {RefreshNotifications, breakpoint, openConnect, user, isAuthenticated} = useContext(AppContext);

    useDocumentTitle("iRaz | Decentralized NFT Raffles");

    async function GetData(){
        try{
            const recent = await RaffleApi.mostRecent();
            setRecentRaffles(recent);

            const popular = await CollectionApi.popular();
            setPopularCollections(popular);

            if(isAuthenticated){
                RefreshNotifications();
            }
        } catch(error){

        }
    }

    function GoToProfile(address: string){
        window.scrollTo(0, 0);
        navigate(`/user/${address}`);
    }

    function GoToExplore(){
        window.scrollTo(0, 0);
        navigate('/explore');
    }

    function HandleLogin(){
        if(!isAuthenticated){
            openConnect();
        } else {
            GoToProfile(user?.ethAddress as string);
        }
    }

    useEffect(() => {
        GetData();
    }, [])

    // state variable for the enter raffle modal
    const [showEnterRaffle, setShowEnterRaffle] = useState<boolean>(false);
    const [enterRaffleItem, setEnterRaffleItem] = useState<Raffle | null>(null);
    const openEnterRaffleModal = (item:Raffle) => {
        setEnterRaffleItem(item);
        setShowEnterRaffle(true);
    }
    const closeEnterRaffleModal = () => {
        setShowEnterRaffle(false);
        setEnterRaffleItem(null);
    }

    const SlidesPerView:number = useMemo(() => {
        switch(breakpoint){
            case "xs":
                return 1;
            case "sm":
                return 2;
            case "md":
                return 3;
            case "lg":
                return 4;
            case "xl":
                return 5;
        }
    }, [breakpoint]);

    return (
        <>
        <EnterRaffle
            open={showEnterRaffle}
            close={closeEnterRaffleModal}
            item={enterRaffleItem}
            callback={GetData}
        />
        <Grid container justifyContent="center">
            <Grid item xs={11} lg={8} mt={8} mb={2}>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    Recently Created Raffles
                </Typography>
            </Grid>
            <Grid item xs={12} mt={3}>
                {recentRaffles.length > 0 ?
                <Swiper
                    slidesPerView={SlidesPerView}
                    spaceBetween={15}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                >
                    {recentRaffles.map((i) => {
                        return (
                            <SwiperSlide>
                                <Box ml={2} mr={2}>
                                    <RaffleCard raffle={i} openEnterModal={openEnterRaffleModal} />
                                </Box>
                            </SwiperSlide>
                        );
                    })}
                </Swiper> : <Skeleton width={400} height={500} sx={{mx: "auto"}} /> }
            </Grid>
            <Grid item xs={10} md={7} textAlign="center" mt={6}>
                <Typography
                    sx={{ fontWeight: 600, fontSize: "30px", paddingBottom: 0 }}
                >
                    Create and Enter the First
                    <br />
                    Decentralized NFT Raffles
                </Typography>
            </Grid>
            <Grid item xs={12} md={7} textAlign="center" mt={2}>
                <Button size="medium" variant="contained" sx={{marginRight: "10px", fontSize: "20px"}} onClick={GoToExplore}>
                    Explore
                </Button>
                <Button size="medium" variant="contained" sx={{marginLeft: "10px", fontSize: "20px"}} onClick={HandleLogin}>
                    Create
                </Button>
            </Grid>
            <Grid item xs={11} md={8} mt={8} mb={2}>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    Most Popular Collections
                </Typography>
            </Grid>
            <Grid item xs={12} mt={3} mb={3}>
                {popularCollections.length > 0 ?
                <Swiper
                    slidesPerView={SlidesPerView}
                    spaceBetween={15}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                >
                    
                    {popularCollections.map((i) => {
                        return (
                            <SwiperSlide>
                                <Box ml={2} mr={2}>
                                    <CollectionCard collection={i} />
                                </Box>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                : <Skeleton width={400} height={500} sx={{mx: "auto"}} /> }
            </Grid>
        </Grid>
        </>
    );
}

export default Home;