import { createTheme, lighten } from "@mui/material/styles";

export const DarkTheme = createTheme({
    typography: {
        fontFamily: ["Montserrat", "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#4b006e",
            dark: "#01000c",
        },
        secondary: {
            main: "#757575",
            dark: "#212121",
        },
        background: {
            default: "#0a0a0a"
        },
        mode: "dark"
    },
    components: {
        MuiTypography: {
            defaultProps: {
                color: "white"
            }
        },
        MuiIconButton: {
            defaultProps: {
                color: "inherit"
            }
        },
        MuiIcon: {
            defaultProps: {
                color: "inherit"
            }
        },
        MuiSvgIcon: {
            defaultProps: {
                color: "secondary"
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: lighten("#4b006e", 0.6)
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: lighten("#4b006e", 0.6)
                        
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: lighten("#4b006e", 0.6)
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 15,
                }
            }
        }
    },
});
