import { ethers } from "ethers";
import iRaz from "../contracts/iraz";

const iRazAddress = process.env.REACT_APP_IRAZ_ADDRESS as string;

const GetListingFee = async () => {
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();
    const iRazContract = new ethers.Contract(iRazAddress, iRaz.abi, signer);
    const result = await iRazContract.GetListingFee();
    return result.toString();
}

const CheckApprovalForAll = async (nftContract: string, address: string) => {
    // Turn the parameters into eth addresses
    const hash = ethers.getAddress(nftContract);
    const user = ethers.getAddress(address);

    // Get the provider and signer for the transaction
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();

    // This is the Global ABI that all ERC721 contract have, you can check if the requested address
    // has permission to request a transfer of that nft contract OR request permission 
    const ABI = [
        "function setApprovalForAll(address operator, bool _approved)",
        "function isApprovedForAll(address owner, address operator) view returns (bool)",
    ];
    const contract = new ethers.Contract(hash, ABI, signer);

    // If not approved then we will want to request approval for the raffle address for that NFT collection
    // Then in the future they will not need to request permission again since they approved for all
    const isApproved = await contract.isApprovedForAll(user, iRazAddress);
    if (!isApproved) {
        const setApprovalTx = await contract.setApprovalForAll(iRazAddress, true);
        await setApprovalTx.wait();
    }

    // return the status after the transaction request
    var status = await contract.isApprovedForAll(user, iRazAddress);
    return status === true;
}

const CreateRaffle = async(nftContract:string, tokenId:string, spots: number, entryPrice: number, premium:boolean, endTime: number) => {
    // Get the provider and signer for the transaction
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();

    // Get the listing fee to send with the transaction
    const iRazContract = new ethers.Contract(iRazAddress, iRaz.abi, signer);
    const listingFee = await iRazContract.GetListingFee();

    const entryEth = ethers.parseEther(entryPrice.toString());
    
    const createTx = await iRazContract.CreateRaffle(nftContract, tokenId, spots, entryEth, premium, endTime, {value: listingFee})
    const success = await createTx.wait();

    return success;
}
const PurchaseEntry = async (id:number, spots: number, callback: VoidFunction) => {
    // Get the provider and signer for the transaction
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();
    
    // purchase a entry for the raffle
    const iRazContract = new ethers.Contract(iRazAddress, iRaz.abi, signer);

    provider.on("pending", (txHash) => {
        callback();
    });

    const raffle = await iRazContract.GetRaffle(id);
    const purchaseTx = await iRazContract.PurchaseEntry(id, spots, {
        value: BigInt(raffle.entryPrice) * BigInt(spots),
    });

    const success = await purchaseTx.wait();

    return success;
}

const GetWinner = async (id: number) => {
    // Get the provider and signer for the transaction
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();
    
    // create the eth contract object
    const iRazContract = new ethers.Contract(iRazAddress, iRaz.abi, signer);

    // request a random number and winner from chainlink vrf
    const getWinnerTx = await iRazContract.PickRaffleWinner(id);
    const success = await getWinnerTx.wait();

    return success;
}

const ClaimRaffle = async(id: number) => {
    // Get the provider and signer for the transaction
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();

    // purchase a entry for the raffle
    const iRazContract = new ethers.Contract(iRazAddress, iRaz.abi, signer);

    // create the claim transaction for the user
    const claimTx = await iRazContract.ClaimRaffle(id);
    const success = await claimTx.wait();

    return success;
}

const CancelRaffle = async(id: number) => {
    // Get the provider and signer for the transaction
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();

    // purchase a entry for the raffle
    const iRazContract = new ethers.Contract(iRazAddress, iRaz.abi, signer);

    // create the cancel raffle transaction for the user
    const cancelTx = await iRazContract.CancelRaffle(id);
    const success = await cancelTx.wait();

    return success;
}

const iRazAdapter = {
    GetListingFee,
    CheckApprovalForAll,
    CreateRaffle,
    PurchaseEntry,
    GetWinner,
    ClaimRaffle,
    CancelRaffle
};

export default iRazAdapter;