import { useContext, useMemo } from "react";
import { SearchResult } from "../../interfaces/search";
import { List, ListItem, ListSubheader } from "@mui/material";
import { AppContext } from "../../context/AppContext";
import SearchListItem from "./SearchListItem";

interface SearchListProps {
    results: SearchResult[]
    focused: boolean;
    hide: VoidFunction;
}

const SearchList = ({results, focused, hide}: SearchListProps) => {
    const { Theme } = useContext(AppContext);

    const {users, collections, categories} = useMemo(() => {
        return {
            users: results.filter((i) => i.category === "Users"),
            collections: results.filter((i) => i.category === "Collections"),
            categories: results.map((i) => i.category),
        }
    }, [results]);

    const UserElements = useMemo(() => function(){
        if(users.length > 0){
            return <>
            <ListSubheader sx={{textAlign: "left", fontSize: "16px"}}>Users</ListSubheader>
            {users.map((i) => <SearchListItem item={i} hide={hide} />)}
            </>
        }
        return <></>;
    }, [users]);

    const CollectionElements = useMemo(() => function(){
        if(collections.length > 0){
            return <>
            <ListSubheader sx={{textAlign: "left", fontSize: "16px"}}>Collections</ListSubheader>
            {collections.map((i) => <SearchListItem item={i} hide={hide} />)}
            </>
        }
        return <></>;
    }, [collections]);

    return (
        <List
            sx={{
                width: '100%',
                bgcolor: Theme.palette.secondary.dark,
                position: 'absolute',
                overflow: 'auto',
                maxHeight: 300,
                zIndex: 100,
                display: focused ? "block" : "none",
                borderRadius: "0px 0px 5px 5px"
            }}
            subheader={<li />}
            >

            {categories.length > 0 &&
                <>
                <UserElements/>
                <CollectionElements/>
                </>
            }

            {results.length === 0 &&
            <ListItem sx={{mt: 1}}>No Results...</ListItem>}
        </List>
    )
}

export default SearchList;