import { Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import {useState} from "react";
import iRazAdminAdapter from "../adapters/iRazAdminAdapter";

const Admin = () => {
    const [collectionStatus, setCollectionStatus] = useState<boolean | null>(null);
    const [checkCollectionAddress, setCheckCollectionAddress] = useState<string>("");

    const [verifyStatus, setVerifyStatus] = useState<null | true | false | "pending">(null);
    const [unVerifyStatus, setUnVerifyStatus] = useState<null | true | false | "pending">(null);

    async function CheckCollectionStatus(){
        setCollectionStatus(null);
        try{
            const verified = await iRazAdminAdapter.IsVerified(checkCollectionAddress);
            console.log(verified)
            setCollectionStatus(verified);
        } catch(error){
            alert("There was an error checking the verification status: " + error);
        }
    }

    async function AddToVerified(){
        setVerifyStatus("pending");
        try{
            const verified = await iRazAdminAdapter.AddToVerified(checkCollectionAddress);
            setVerifyStatus(verified);
        } catch(error){
            setVerifyStatus(null);
            //alert("There was an error verifying the collection: " + error);
        }
        
    }

    async function RevokeVerified(){
        setUnVerifyStatus("pending");
        try{
            const verified = await iRazAdminAdapter.RevokeVerified(checkCollectionAddress);
            setUnVerifyStatus(verified);
        } catch(error){
            setUnVerifyStatus(null);
            //alert("There was an error verifying the collection: " + error);
        }
        
    }

    return (
        <Box mt={10} minHeight="80vh">
            <Typography variant="h3">Admin Panel</Typography>
            <Grid container justifyContent="center" mt={6}>
                <Grid item xs={9} mb={6}>
                    <Box border={1} borderColor="lightgray" width="fit-content" mx="auto" borderRadius={5} p={3}>
                        <Typography variant="h5">Check Collection Verification status</Typography>
                        <TextField placeholder="Collection Hash" sx={{width: 500, mb: 1}} onChange={(e) => setCheckCollectionAddress(e.target.value)} value={checkCollectionAddress}></TextField>
                        <Button variant="contained" size="medium" onClick={CheckCollectionStatus}>Check</Button>
                        {collectionStatus !== null && <Typography color={collectionStatus ? "green" : "error"}>{collectionStatus ? "Verified" : "Not Verified"}</Typography>}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box border={1} borderColor="lightgray" width="fit-content" mx="auto" borderRadius={5} p={3}>
                        <Typography variant="h4" sx={{textDecoration: "underline", mb: 1}}>Verify Collection</Typography>
                        <TextField placeholder="Collection Hash" sx={{width: 500, mb: 1}} onChange={(e) => setCheckCollectionAddress(e.target.value)} value={checkCollectionAddress}></TextField><br />
                        <Button variant="contained" size="medium" onClick={AddToVerified}>Verify</Button>
                        {verifyStatus === "pending" && <><br /><CircularProgress /></>}
                        {verifyStatus !== null && verifyStatus !== "pending" && <Typography color={!verifyStatus ? "error" : "green"}>{!verifyStatus ? "Verification Failed" : "Verification Success"}</Typography>}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box border={1} borderColor="lightgray" width="fit-content" mx="auto" borderRadius={5} p={3}>
                        <Typography variant="h4" sx={{textDecoration: "underline", mb: 1}}>Un-verify Collection</Typography>
                        <TextField placeholder="Collection Hash" sx={{width: 500, mb: 1}} onChange={(e) => setCheckCollectionAddress(e.target.value)} value={checkCollectionAddress}></TextField><br />
                        <Button variant="contained" size="medium" onClick={RevokeVerified}>Un-Verify</Button>
                        {unVerifyStatus === "pending" && <><br /><CircularProgress /></>}
                        {unVerifyStatus !== null && unVerifyStatus !== "pending" && <Typography color={!unVerifyStatus ? "error" : "green"}>{!unVerifyStatus ? "Revoke Verification Failed" : "Revoke Verification Success"}</Typography>}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Admin;