import { AppContext } from "../context/AppContext";
import {useContext, useMemo} from "react";

const Styles = () => {
    const {Theme, darkMode, breakpoint} = useContext(AppContext);

    const ModalStyle = useMemo(() => {
        return {
            position: "absolute" as "absolute",
            zIndex: 15,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: breakpoint === "xs" || breakpoint === "sm" ? 320 : 450,
            bgcolor: Theme.palette.secondary.dark,
            boxShadow: 24,
            p: 4,
            ".MuiFormLabel-root": {
                color: darkMode ? "white" : "black"
            },
            '.MuiInputBase-input': {
                color: darkMode ? "white" : "black"
            }
        };
    }, [breakpoint, Theme, darkMode]);

    return {
        ModalStyle
    }
}

export default Styles;