import { EvmNft } from "@moralisweb3/evm-utils";
import { Collection } from "../interfaces/collection";
import { axios } from "./configs/axios";
import { Activity } from "../interfaces/activity";

const ENDPOINT = "/collections";

// interface CollectionProps {
//     page: number;
// }

const getPage = async (page: number) => {
    const { data } = await axios.get(`${ENDPOINT}?page=${page}`);

    return {
        collections: data.collections as Collection[],
        hasNextPage: data.hasNextPage,
    };
};

const popular = async () => {
    const { data } = await axios.get(`${ENDPOINT}/popular`);

    return data.collections as Collection[];
};

const getById = async (address: string) => {
    const { data } = await axios.get(`${ENDPOINT}/${address}`);

    return {
        collectionRes: data.collection as Collection,
        nftRes: data.nfts as EvmNft[],
        activityRes: data.activity as Activity[],
        watchingRes: data.watching
    }
}

const toggleWatch = async (address: string) => {
    const { data } = await axios.put(`${ENDPOINT}/${address}/watch`);

    return data;
}

const refreshMeta = async (address: string) => {
    const { data } = await axios.put(`${ENDPOINT}/${address}/refresh-meta`);

    return data;
}

const CollectionApi = {
    popular,
    getPage,
    getById,
    toggleWatch,
    refreshMeta
};

export default CollectionApi;
