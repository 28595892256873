import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
    const navigate = useNavigate();

    function GoBack(){
        window.scrollTo(0, 0);
        navigate(-1);
    }

    return(
        <Box minHeight="90vh">
            <Typography mt={20} variant="h3">Unauthorized</Typography>
            <Typography>You are not an admin</Typography>

            <Button sx={{mt: 4}} variant="contained" size="medium" onClick={GoBack}>Go Back</Button>
        </Box>
    );
}

export default Unauthorized;