import { SortOrder } from "./types";

export const SortOrders = [
    { key: "price_low_high" as SortOrder, value: "Price: Low to High" },
    { key: "price_high_low" as SortOrder, value: "Price: High to Low" },
    { key: "token_id_low_high" as SortOrder, value: "Token ID: Low to High" },
    { key: "token_id_high_low" as SortOrder, value: "Token ID: High to Low" },
    { key: "total_spots_low_high" as SortOrder, value: "Total Spots: Low to High" },
    { key: "total_spots_high_low" as SortOrder, value: "Total Spots: High to Low" },
    { key: "available_spots_low_high" as SortOrder, value: "Available Spots: Low to High" },
    { key: "available_spots_high_low" as SortOrder, value: "Available Spots: High to Low" },
    { key: "time_newest_first" as SortOrder, value: "Time: Newest First" },
    { key: "time_oldest_first" as SortOrder, value: "Time: Oldest First" },
];
