import { SearchCollection } from "../interfaces/collection";
import { SearchResult } from "../interfaces/search";
import { SearchUser } from "../interfaces/user";
import { axios } from "./configs/axios";

const ENDPOINT = "/search";

const search = async (query: string) => {
    const { data } = await axios.get(`${ENDPOINT}?query=${query}`);

    const users = data.users as SearchUser[];
    const collections = data.collections as SearchCollection[];

    const searchData = [
        ...users.map(i => {
            return {
                name: i.username,
                address: i.ethAddress,
                category: "Users"
            }
        }),
        ...collections.map(i => {
            return {
                name: i.name,
                address: i.nftContract,
                category: "Collections"
            }
        })
    ] as SearchResult[];

    return {
        searchData
    }
};

const SearchApi = {
    search
};

export default SearchApi;
