import {
    alpha,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Skeleton,
    styled,
    Typography,
} from "@mui/material";
import { Raffle } from "../interfaces/raffle";
import {formatEther} from "ethers";
import { useNavigate } from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import { AppContext } from "../context/AppContext";
import { CheckIPFS } from "../utils/nftUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import RaffleFloorPriceBadge from "./badges/RaffleFloorPriceBadge";

interface RaffleCardProps {
    raffle: Raffle;
    openEnterModal: (item: Raffle) => void;
}

const Overlay = styled("div")({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#000000d6",
    '.MuiTypography-root ': {
        position: "relative",
        top: "40%",
        transform:" translateY(-50%)"
    }
});

const FloorPrice = styled(Typography)({
    position: "absolute",
    width: "fit-content",
    fontWeight: 500,
    background: alpha("#4b006e", 0.6),
    right: 5,
    bottom: 8,
    padding: "2px 5px",
    border: "2px solid #4b006e",
    borderRadius: "5px",
    svg: {
        marginLeft: 3
    }
});

const RaffleCard = ({ raffle, openEnterModal }: RaffleCardProps) => {
    const navigate = useNavigate();
    const {Theme, openConnect, isAuthenticated} = useContext(AppContext);
    const [imageUrl, setImageUrl] = useState<string| null>("");


    function OpenModal(){
        if(isAuthenticated){
            openEnterModal(raffle);
        } else {
            openConnect();
        }
    }

    function ViewRaffle(){
        window.scrollTo(0, 0);
        navigate(`/raffle/${raffle.raffleId}`);
    }

    useEffect(() => {
        setImageUrl(CheckIPFS(raffle.image));
    }, [raffle])

    const RaffleOverlay = () => {
        if(raffle.status === "cancelled"){
            return <Overlay>
                <Typography color="error" fontSize="32px" fontWeight={600}>
                    Cancelled
                </Typography>
            </Overlay>
        } else if (raffle.status === "claimed"){
            return <Overlay>
                <Typography color={Theme.palette.success.main} fontSize="32px" fontWeight={600} lineHeight="32px">
                    Claimed
                </Typography>
                <Typography fontSize="22px" fontWeight={600} color={Theme.palette.success.main} >
                    by {raffle.winnerName}
                </Typography>
            </Overlay>
        }

        return <></>
    }

    const BorderColor = () => {
        switch(raffle.status){
            case "cancelled":
                return Theme.palette.error.main;
            case "claimed":
                return Theme.palette.success.main;
            default:
                return "darkgrey";
        }
    }

    return (
        <Box sx={{ minWidth: 275 }}>
            <Card
                variant="outlined"
                sx={{ borderRadius: "25px", borderWidth: "3px", borderColor: BorderColor(), backgroundColor: Theme.palette.secondary.dark, position: "relative" }}
            >
                <RaffleOverlay />
                <CardContent>
                    <Box position="relative">
                        <RaffleFloorPriceBadge floorPrice={raffle.collectionData.floorPrice} />
                        {imageUrl ?
                        <img
                            src={imageUrl}
                            style={{ width: "100%", height: "auto" }}
                            alt=""
                        />: <Skeleton />}
                    </Box>
                    <Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography
                                sx={{ fontSize: "22px", fontWeight: 600 }}
                            >
                                {raffle.name}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography
                                sx={{ fontSize: "18px", fontWeight: 500 }}
                            >
                                {formatEther(raffle.entryPrice)} ETH
                            </Typography>
                            <Typography
                                sx={{ fontSize: "18px", fontWeight: 500 }}
                            >
                                {raffle.entryCount} / {raffle.spots}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions sx={{ borderTop: 1, borderColor: "darkgrey" }}>
                    <Grid container p={2} display="flex">
                        <Grid item xs={6} textAlign="left">
                            <Button variant="contained" size="medium" onClick={ViewRaffle}>
                                View
                            </Button>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                            {raffle.status === "open" && (raffle.endTime > 0 ? Math.floor(Date.now() / 1000) < raffle.endTime : true) &&
                            <Button variant="contained" size="medium" onClick={OpenModal}
                                disabled={raffle.entryCount >= raffle.spots}>
                                Enter
                            </Button>
                            }
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Box>
    );
};

export default RaffleCard;
