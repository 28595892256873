import { alpha, Box, Button, Drawer, FormControl, Grid, IconButton, InputLabel, lighten, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import EnterRaffle from "../components/modals/EnterRaffle";
import {useState, useContext, useEffect, useMemo} from "react";
import { Raffle } from "../interfaces/raffle";
import { ConfirmationNumber, FilterAlt, Inventory, ShowChart, ViewColumn, ViewModule } from "@mui/icons-material";
import { AppContext } from "../context/AppContext";
import { SortOrders } from "../interfaces/consts";
import { SortOrder, StatusFilter } from "../interfaces/types";
import RaffleTab from "../components/profile/RaffleTab";
import CollectionHeader from "../components/collection/CollectionHeader";
import { useParams } from "react-router-dom";
import { Collection } from "../interfaces/collection";
import CollectionApi from "../api/collection";
import { formatEther, parseUnits } from "ethers";
import CollectionFilters from "../components/CollectionFilters";
import { EvmNft } from "@moralisweb3/evm-utils";
import UserNftTab from "../components/collection/UserNftTab";
import RaffleItem from "../components/modals/RaffleItem";
import { Activity } from "../interfaces/activity";
import ActivityDataGrid from "../components/collection/ActivityDataGrid";
import useDocumentTitle from "../hooks/useDocumentTitle";
import NotFound from "../components/NotFound";
import { ToastContainer } from "react-toastify";
import iRazAdminAdapter from "../adapters/iRazAdminAdapter";
import MobileCollectionFilters from "../components/MobileCollectionFilters";

const CollectionPage = () => {
    const {address} = useParams();
    const {Theme, darkMode, RefreshNotifications, breakpoint, user, isAuthenticated} = useContext(AppContext);

    const [noCollection, setNoCollection] = useState<boolean>(false);
    const [verified, setVerified] = useState<boolean>(false);
    const [watching, setWatching] = useState<boolean>(false);
    const toggleWatching = () => setWatching(oldValue => !oldValue);

    const [collection, setCollection] = useState<Collection | null>(null);
    const [raffles, setRaffles] = useState<Raffle[]>([]);
    const [userNfts, setUserNfts] = useState<EvmNft[]>([]);
    const [activity, setActivity] = useState<Activity[]>([]);

    const [rafflesOpenCount, setRafflesOpenCount] = useState<number>(0);
    const [lowestCost, setLowestCost] = useState<number>(0);

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const toggleDrawer = () => setOpenDrawer(oldValue => !oldValue);

    useDocumentTitle(collection && collection.name ? `${collection.name} - Collection | iRaz`: "Collection | iRaz");

    async function GetCollection(address: string){
        try{
            const {collectionRes, nftRes, activityRes, watchingRes} = await CollectionApi.getById(address);

            console.log({
                collectionRes,
                nftRes
            })

            const raffleRes = collectionRes.raffles;

            setRaffles(raffleRes || []);

            delete(collectionRes.raffles);
            setCollection(collectionRes);

            setUserNfts(nftRes);

            setActivity(activityRes);
            setWatching(watchingRes);

            if(isAuthenticated){
                RefreshNotifications();
            }
        } catch(error:any){
            if(error.response.status === 404){
                setNoCollection(true);
            } else {
                console.log('Error fetching collection', error);
            }
            
        }
    }

    function RefreshData(){
        if(address){
            GetCollection(address as string);
        }
        if(isAuthenticated){
            RefreshNotifications();
        }
    }

    useEffect(() => {
        async function CheckVerificationStatus(){
            const isVerified = await iRazAdminAdapter.IsVerified(address as string);
            setVerified(isVerified);
        }

        if(address && address.length > 0){
            GetCollection(address);

            CheckVerificationStatus();
        }
    }, [address, isAuthenticated])

    useEffect(() => {
        const rafflesOpen = raffles.filter(i => i.status === "open");
        setRafflesOpenCount(rafflesOpen.length);

        if(rafflesOpen && rafflesOpen.length > 0){
            const sortedCheapestFirst = rafflesOpen.sort((a,b) => {
                const aPrice = parseUnits(a.entryPrice, "wei");
                const bPrice = parseUnits(b.entryPrice, "wei");
                if(aPrice < bPrice) return -1;
                if(aPrice > bPrice) return 1;
                return 0;
            });

            setLowestCost(Number(formatEther(sortedCheapestFirst[0].entryPrice)));
        } else {
            setLowestCost(0.0);
        }
    }, [raffles])

    // state variable for the enter raffle modal
    const [showEnterRaffle, setShowEnterRaffle] = useState<boolean>(false);
    const [enterRaffleItem, setEnterRaffleItem] = useState<Raffle | null>(null);
    const openEnterRaffleModal = (item:Raffle) => {
        setEnterRaffleItem(item);
        setShowEnterRaffle(true);
    }
    const closeEnterRaffleModal = () => {
        setShowEnterRaffle(false);
        setEnterRaffleItem(null);
    }

    // state variables for the raffle item modal
    const [showRaffleItem, setShowRaffleItem] = useState<boolean>(false);
    const [raffleItem, setRaffleItem] = useState<EvmNft | null>(null);
    const openRaffleModal = (item:EvmNft) => {
        setRaffleItem(item);
        setShowRaffleItem(true);
    }
    const closeRaffleModal = () => {
        setShowRaffleItem(false);
        setRaffleItem(null);
    }

    const [currentTab, setCurrentTab] = useState<number>(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    }

    // search bar filter
    const [searchQuery, setSearchQuery] = useState<string>("");
    const handleSearchChange = (event:any) => setSearchQuery(event.target.value);

    // sort order for the raffles
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrders[0].key);
    const handleSortOrderChange = (event:any) => {
        console.log(event.target.value)
        setSortOrder(event.target.value);
    }

    // size settings for the raffle cards
    const [cardSize, setCardSize] = useState<number>(300);
    const setLargeCards = () => setCardSize(450);
    const setSmallCards = () => setCardSize(300);

    // filters for min/max price
    const [priceRange, setPriceRange] = useState<{min: number, max: number} |  null>(null)
    const applyPriceFilter = (min: number, max: number) => {
        console.log(min, max)
        setPriceRange({min, max});
    }
    const removePriceFilter = () => {
        setPriceRange(null);
    }

    // filters for created raffles status
    const [statusFilters, setStatusFilters] = useState<StatusFilter[]>([]);
    const addStatusFilter = (status:StatusFilter) => {
        if(!statusFilters.includes(status)) setStatusFilters(oldFilters => [...oldFilters, status]);
    }
    const removeStatusFilter = (status:StatusFilter) => {
        setStatusFilters(oldFilters => oldFilters.filter(i => i !== status));
    }
    const resetStatusFilter = () => {
        setStatusFilters([]);
    }

    const raffleTabBorderLeft = useMemo(() => {
        if(breakpoint === "sm" || breakpoint === "xs" || Number(currentTab !== 0)){
            return 0;
        }

        return 1;
    }, [breakpoint, currentTab])

    if(noCollection){
        return <NotFound searchValue={address as string} type="collection" />
    } else {

        return (
            <>
            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={toggleDrawer}
                sx={{'.MuiDrawer-paper': {backgroundColor: alpha(Theme.palette.background.paper, 0.3)}}}>
                <MobileCollectionFilters
                    currentTab={currentTab}
                    applyPriceFilter={applyPriceFilter}
                    resetStatusFilter={resetStatusFilter}
                    addStatusFilter={addStatusFilter}
                />
                <Paper sx={{py: 2}}>
                    <FormControl sx={{marginRight: "10px", mt: 2}} fullWidth focused={false} >
                        <InputLabel color="primary">Sort By:</InputLabel>
                        <Select
                            value={sortOrder}
                            label="Sort By:"
                            onChange={handleSortOrderChange}
                            sx={{width: "260px", textAlign: "left"}}
                        >
                            {SortOrders.map(i => {
                                return <MenuItem value={i.key}>{i.value}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Paper>
                <Button size="medium" variant="contained" onClick={toggleDrawer} sx={{mt: 2}}>Close</Button>
            </Drawer>
            <Grid container justifyContent={"center"} marginTop={4}>
                <EnterRaffle 
                    open={showEnterRaffle}
                    close={closeEnterRaffleModal}
                    item={enterRaffleItem}
                    callback={RefreshData}
                />
                <RaffleItem 
                    open={showRaffleItem}
                    close={closeRaffleModal}
                    item={raffleItem}
                    callback={RefreshData}
                />
                <CollectionHeader collection={collection} rafflesOpenCount={rafflesOpenCount} lowestCost={lowestCost} watching={watching} toggleWatching={toggleWatching} verified={verified} />
                <Grid item xs={12} marginTop={4}>
                    <Grid container>
                        <Grid
                            item
                            xs={0}
                            md={2}
                            borderBottom={1}
                            borderColor="darkgrey"
                        ></Grid>
                        <Grid item xs={12} md={10} borderBottom={1} borderColor="darkgrey">
                            <Box sx={{ width: "100%" }}>
                                <Tabs
                                    value={currentTab}
                                    onChange={handleTabChange}
                                    aria-label="basic tabs example"
                                    variant="fullWidth"
                                >
                                    <Tab
                                        sx={{ fontSize: {xs: "14px", md: "16px", lg: "18px"}, 
                                            "&.Mui-selected": {
                                                color: lighten(Theme.palette.primary.main, 0.3),
                                                background: lighten(Theme.palette.background.default, 0.05)
                                            } 
                                        }}
                                        icon={<ConfirmationNumber />}
                                        iconPosition="start"
                                        label="Raffles"
                                        value={0}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        sx={{ fontSize: {xs: "14px", md: "16px", lg: "18px"},
                                            "&.Mui-selected": {
                                                color: lighten(Theme.palette.primary.main, 0.3),
                                                background: lighten(Theme.palette.background.default, 0.05)
                                            } 
                                        }}
                                        icon={<ShowChart />}
                                        iconPosition="start"
                                        label="Activity"
                                        value={1}
                                        {...a11yProps(1)}
                                    />
                                    {isAuthenticated && (
                                        <Tab
                                            sx={{ fontSize: {xs: "14px", md: "16px", lg: "18px"},
                                                "&.Mui-selected": {
                                                    color: lighten(Theme.palette.primary.main, 0.3),
                                                    background: lighten(Theme.palette.background.default, 0.05)
                                                } 
                                            }}
                                            icon={<Inventory />}
                                            iconPosition="start"
                                            label="Owned NFTs"
                                            value={2}
                                            {...a11yProps(2)}
                                        />
                                    )}
                                </Tabs>
                            </Box>
                        </Grid>
                        {currentTab === 0 &&
                        <Grid item xs={2} display={{xs: "none", md: "block"}} minHeight={"70vh"}>
                            <CollectionFilters
                                currentTab={currentTab}
                                applyPriceFilter={applyPriceFilter}
                                resetStatusFilter={resetStatusFilter}
                                addStatusFilter={addStatusFilter}
                            />
                        </Grid>
                        }
                        <Grid item xs={12} md={currentTab === 0 ? 10 : 12} borderLeft={raffleTabBorderLeft} borderColor="darkgrey" minHeight={"70vh"}>
                            <Box margin={{xs: 1, lg: 5}} marginTop={2} marginBottom={0} textAlign="right" display="flex" justifyContent="space-between">
                                {(currentTab === 0 || currentTab === 2) && <TextField variant="outlined" label="Search" sx={{width: "500px"}} placeholder="NFT Name or TokenID" value={searchQuery} onChange={handleSearchChange}></TextField> }
                                <Box display="flex" justifyContent="space-around">
                                    {currentTab === 0 &&
                                    <FormControl sx={{marginRight: "10px", display: {xs: "none", md: "block"}}}>
                                        <InputLabel>Sort By:</InputLabel>
                                        <Select
                                            value={sortOrder}
                                            label="Sort By:"
                                            onChange={handleSortOrderChange}
                                            sx={{width: "260px", textAlign: "left"}}
                                        >
                                            {SortOrders.map(i => {
                                                return <MenuItem value={i.key}>{i.value}</MenuItem>;
                                            })}
                                        </Select>
                                    </FormControl>
                                    }
                                    {currentTab === 0 &&
                                    <>
                                    <IconButton sx={{color: "black", display: {xs: "none", md: "block"}}} onClick={setLargeCards}>
                                        <ViewColumn sx={{margin: "auto", fontSize: "40px"}}/>
                                    </IconButton>
                                    <IconButton sx={{color: "black", display: {xs: "none", md: "block"}}} onClick={setSmallCards}>
                                        <ViewModule sx={{margin: "auto", fontSize: "40px"}}/>
                                    </IconButton>
                                    <IconButton sx={{color: "black", display: {xs: "block", md: "none"}}} onClick={toggleDrawer}>
                                        <FilterAlt sx={{margin: "auto", fontSize: "40px"}}/>
                                    </IconButton>
                                    </>}
                                </Box>
                            </Box>
                            <RaffleTab currentTab={currentTab} 
                                index={0} 
                                raffles={raffles} 
                                removeFilter={() => {}} 
                                filters={[]} 
                                priceFilter={priceRange} 
                                resetPriceFilter={removePriceFilter} 
                                statusFilters={statusFilters}
                                removeStatusFilter={removeStatusFilter}
                                userAddress={user?.ethAddress as string}
                                sortOrder={sortOrder}
                                cardSize={cardSize}
                                searchQuery={searchQuery}
                                openEnterModal={openEnterRaffleModal} />
                            <ActivityDataGrid
                                index={1}
                                currentTab={currentTab}
                                activity={activity}
                                title="Recent Collection Activity"
                            />
                            <UserNftTab
                                currentTab={currentTab}
                                isUser={isAuthenticated}
                                nfts={userNfts}
                                cardSize={cardSize}
                                searchQuery={searchQuery}
                                raffleItem={openRaffleModal}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </>
        )
    }
}

export default CollectionPage;