import { createConfig, configureChains, mainnet, sepolia } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'

const chain = process.env.REACT_APP_NODE_ENV === "production" ? mainnet : sepolia;

const { publicClient, webSocketPublicClient } = configureChains(
  [chain],
  [publicProvider()],
)

export const wagmiConfig = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
})
