import { Notification } from "../interfaces/notification";
import { axios } from "./configs/axios";

const ENDPOINT = "/notifications";

const getAll = async () => {
    const { data } = await axios.get(`${ENDPOINT}`);

    return data.notifications as Notification[];
};

const markAllAsSeen = async () => {
    const { data } = await axios.post(`${ENDPOINT}/user-seen`);

    return data;
}


const NotificationApi = {
    getAll,
    markAllAsSeen
};

export default NotificationApi;
