import { ethers } from "ethers";
import iRazPremiumNFT from "../contracts/irazPremiumNft";

const iRazPremiumAddress = process.env.REACT_APP_IRAZ_PREMIUM_ADDRESS as string;

const IsPremium = async (address: string) => {
    const ethereum = (window as any).ethereum;
    const provider = new ethers.BrowserProvider(ethereum);
    await provider.send("eth_requestAccounts", []); // <- this promps user to connect metamask
    const signer = await provider.getSigner();

    const iRazPremiumNFTContract = new ethers.Contract(iRazPremiumAddress, iRazPremiumNFT.abi, signer);
    const result = await iRazPremiumNFTContract.IsPremium(address);
    return result
}

const iRazPremiumAdapter = {
    IsPremium
};

export default iRazPremiumAdapter;
