import {
    Box,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import { Collection } from "../interfaces/collection";
import {useContext} from "react";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

interface CollectionCardProps {
    collection: Collection;
}

const CollectionCard = ({ collection }: CollectionCardProps) => {
    const {Theme} = useContext(AppContext);
    const navigate = useNavigate();

    function goToCollection(){
        window.scrollTo(0, 0);
        navigate(`/collection/${collection.nftContract}`);
    }

    return (
        <Box sx={{ minWidth: 275, cursor: "pointer" }} onClick={goToCollection}>
            <Card
                variant="outlined"
                sx={{ borderRadius: "25px", borderColor: "darkgrey", backgroundColor: Theme.palette.secondary.dark }}
            >
                <CardContent>
                    <img
                        src={
                            collection.image ||
                            "https://i.pinimg.com/474x/18/45/61/1845613a68a35c3d138fbfa5d1bc3d7e.jpg"
                        }
                        style={{ width: "100%", height: "auto" }}
                        alt=""
                    />
                    <Box pt={2} pb={0}>
                        <Box textAlign="left">
                            <Typography
                                sx={{ fontSize: "22px", fontWeight: 600 }}
                            >
                                {collection.name}
                            </Typography>
                        </Box>
                    </Box>
                    <Box textAlign="left">
                        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                            {collection.raffleCount} Raffle(s)
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default CollectionCard;
