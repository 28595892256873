import { Box } from "@mui/system";
import TabPanel from "../TabPanel";
import {EvmNft} from "@moralisweb3/evm-utils";
import NFTCard from "../NftCard";
import { Typography } from "@mui/material";
import { useMemo } from "react";

interface NftTabProps {
    currentTab: number;
    isUser: boolean;
    nfts: EvmNft[];
    cardSize: number;
    searchQuery: string;
    raffleItem: (item:EvmNft) => void;
}

const UserNftTab = ({currentTab, nfts, cardSize = 330, isUser, searchQuery, raffleItem}:NftTabProps) => {

    function SearchItems(element:EvmNft, index: number, array: EvmNft[]){
        if(element.name){
            if(element.name?.toLowerCase().includes(searchQuery.toLowerCase())) return true;
        }
        if(element.tokenId.toString().includes(searchQuery.toLowerCase())) return true;
        return false;
    }

    const NftResult = useMemo(() => {
        var filteredNfts = nfts;

        if(searchQuery !== ""){
            filteredNfts = filteredNfts.filter(SearchItems);
        }

        if(filteredNfts.length > 0){
            return (
                <>
                {filteredNfts.map((i: EvmNft) => {
                    return (
                        <Box margin={2} minWidth={cardSize} maxWidth={cardSize}>
                            <NFTCard nft={i} isUser={isUser} raffleItem={raffleItem} />
                        </Box>
                    );
                })}
                </>
            )
        }

        return <Typography variant="h5">You do not own any NFTs for this collection.</Typography>
    }, [searchQuery, cardSize, isUser, nfts]);

    return (
        <TabPanel value={currentTab} index={2}>
            <Box display="flex" justifyContent={{xs: "center", md: "start"}} flexWrap={"wrap"}>
                {NftResult}
            </Box>
        </TabPanel>
    );
}

export default UserNftTab;