import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { EvmNft } from "@moralisweb3/evm-utils";
import { GetNftImage } from "../utils/nftUtils";
import {useContext} from "react";
import { AppContext } from "../context/AppContext";

interface NFTCardProps {
    nft: EvmNft;
    isUser: boolean;
    raffleItem: (item: EvmNft) => void;
}

const NFTCard = ({nft, isUser, raffleItem}: NFTCardProps) => {
    const {Theme} = useContext(AppContext);

    function CreateRaffle(){
        raffleItem(nft);
    }

    function GetNftName(){
        const metadata = nft.metadata as any;
        if(metadata && metadata.name){
            return metadata.name;
        } else {
            return nft.name + " #" + nft.tokenId; 
        }
    }

    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined" sx={{ borderRadius: "25px", borderColor: "darkgrey", backgroundColor: Theme.palette.secondary.dark }}>
                <CardContent>
                    <img src={GetNftImage(nft)} style={{width: "100%", height: "auto"}} alt="" />
                    <Box pt={2} pb={0}>
                        <Box textAlign="left">
                            <Typography
                                sx={{ fontSize: "20px", fontWeight: 600 }}
                            >
                                {GetNftName()}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
                {isUser &&
                <CardActions sx={{ borderTop: 1, borderColor: "darkgrey" }}>
                    <Box p={2}>
                        <Button variant="contained" size="medium" onClick={CreateRaffle}>
                            Raffle Item
                        </Button>
                    </Box>
                </CardActions>
                }
            </Card>
        </Box>
    );
}

export default NFTCard;