import { AccountCircle, ConfirmationNumber, Create, FilterAlt, Inventory, Language, ShowChart, ViewColumn, ViewModule, Visibility } from "@mui/icons-material";
import { alpha, Box, Button, Drawer, FormControl, Grid, IconButton, InputLabel, lighten, MenuItem, Paper, Select, Skeleton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState, useMemo } from "react";
import ProfileFilters from "../components/ProfileFilters";
import { NFTCollection } from "../interfaces/collection";
import { TruncateEthAddress } from "../utils/formatAddress";
import { Raffle } from "../interfaces/raffle";
import UserApi from "../api/user";
import { ProfileUser } from "../interfaces/user";
import EditProfile from "../components/modals/EditProfile";
import { EvmNft } from "@moralisweb3/evm-utils";
import NftTab from "../components/profile/NftTab";
import RaffleTab from "../components/profile/RaffleTab";
import { CollectionFilter } from "../interfaces/collectionFilter";
import { SortOrder, StatusFilter } from "../interfaces/types";
import { getNftCollectionFilters, getRaffleCollectionFilters } from "../utils/getCollectionFilters";
import { SortOrders } from "../interfaces/consts";
import RaffleItem from "../components/modals/RaffleItem";
import EnterRaffle from "../components/modals/EnterRaffle";
import { AppContext } from "../context/AppContext";
import { Activity } from "../interfaces/activity";
import ActivityDataGrid from "../components/collection/ActivityDataGrid";
import useDocumentTitle from "../hooks/useDocumentTitle";
import NotFound from "../components/NotFound";

const Profile = () => {
    const {address} = useParams();
    const {Theme, RefreshNotifications, breakpoint, user, isAuthenticated} = useContext(AppContext);

    const [noUser, setNoUser] = useState<boolean>(false);

    const [isUser, setIsUser] = useState<boolean>(false);
    const [userData, setUserData] = useState<ProfileUser | null>(null);
    const [activity, setActivity] = useState<Activity[]>([]);

    useDocumentTitle(userData && userData.username ? `${userData.username} - Profile | iRaz` : "Profile | iRaz");

    const [showAbout, setShowAbout] = useState<boolean>(false);
    const [showWebsite, setShowWebsite] = useState<boolean>(false);

    const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
    const handleOpenEditModal = () => setShowEditProfile(true);
    const handleCloseEditModal = () => setShowEditProfile(false);

    // state variables for the raffle item modal
    const [showRaffleItem, setShowRaffleItem] = useState<boolean>(false);
    const [raffleItem, setRaffleItem] = useState<EvmNft | null>(null);
    const openRaffleModal = (item:EvmNft) => {
        setRaffleItem(item);
        setShowRaffleItem(true);
    }
    const closeRaffleModal = () => {
        setShowRaffleItem(false);
        setRaffleItem(null);
    }

    // state variable for the enter raffle modal
    const [showEnterRaffle, setShowEnterRaffle] = useState<boolean>(false);
    const [enterRaffleItem, setEnterRaffleItem] = useState<Raffle | null>(null);
    const openEnterRaffleModal = (item:Raffle) => {
        setEnterRaffleItem(item);
        setShowEnterRaffle(true);
    }
    const closeEnterRaffleModal = () => {
        setShowEnterRaffle(false);
        setEnterRaffleItem(null);
    }
    
    // array list of nfts, and raffles
    const [nfts, setNfts] = useState<EvmNft[]>([]);
    const [rafflesCreated, setRafflesCreated] = useState<Raffle[]>([]);
    const [rafflesEntered, setRafflesEntered] = useState<Raffle[]>([]);
    const [rafflesWatching, setRafflesWatching] = useState<Raffle[]>([]);
    const [rafflesWon, setRafflesWon] = useState<Raffle[]>([]);

    // array list of collections names and hashes for filtering
    const [nftCollections, setNftCollections] = useState<NFTCollection[]>([] as NFTCollection[]);
    const [rafflesCreatedCollections, setRafflesCreatedCollections] = useState<NFTCollection[]>([]);
    const [rafflesEnteredCollections, setRafflesEnteredCollections] = useState<NFTCollection[]>([]);
    const [rafflesWatchingCollections, setRafflesWatchingCollections] = useState<NFTCollection[]>([]);

    const [cardSize, setCardSize] = useState<number>(270);
    const setLargeCards = () => setCardSize(450);
    const setSmallCards = () => setCardSize(270);

    // search bar filter
    const [searchQuery, setSearchQuery] = useState<string>("");
    const handleSearchChange = (event:any) => setSearchQuery(event.target.value);

    useEffect(() => {
        // get the unique collections for the users nfts in their wallet
        if(nfts){
            const nftCollections = getNftCollectionFilters(nfts);
            setNftCollections(nftCollections);
        }
    }, [nfts]);

    useEffect(() => {
        if(rafflesCreated){
            const raffleCollections = getRaffleCollectionFilters(rafflesCreated);
            setRafflesCreatedCollections(raffleCollections);
        }
    }, [rafflesCreated]);

    useEffect(() => {
        if (rafflesEntered) {
            const raffleCollections = getRaffleCollectionFilters(rafflesEntered);
            setRafflesEnteredCollections(raffleCollections);
        }
    }, [rafflesEntered]);

    useEffect(() => {
        if(rafflesWatching){
            const raffleCollections = getRaffleCollectionFilters(rafflesWatching);
            setRafflesWatchingCollections(raffleCollections);
        }
    }, [rafflesWatching])

    // get the public user data from the api
    async function getUser(address: string) {
        try{
            const profileUser = await UserApi.getUserByAddress(address);
            setRafflesCreated(profileUser.rafflesCreated);
            setRafflesEntered(profileUser.rafflesEntered);
            setRafflesWatching(profileUser.rafflesWatching || []);
            setRafflesWon(profileUser.rafflesWon || []);
            setNfts(profileUser.nfts);
            setActivity(profileUser.activity);
            setUserData(profileUser);
        } catch(error:any){
            if(error.response.status === 404){
                setNoUser(true);
            } else {
                console.log('Error fetching user: ', error);
            }
            
        }
    }

    // can be used as a call back to refresh the user data
    const fetchUser = () => {
        if(address){
            getUser(address);
        }
        if(isAuthenticated){
            RefreshNotifications();
        }
    }

    // when the user updates then update state variable
    useEffect(() => {
        if(userData){
            setShowAbout(Boolean(userData.about));
            setShowWebsite(Boolean(userData.website));
        }
    }, [userData])

    // get new user data once the address changed
    useEffect(() => {
        if(address) getUser(address);
    }, [address]);

    useEffect(() => {
        if(isAuthenticated && user && address){
            setIsUser(user.ethAddress.toLowerCase() === address.toLowerCase());
        } else {
            setIsUser(false);
        }
    }, [isAuthenticated, user, address]);

    const [currentTab, setCurrentTab] = useState<number>(0);

    function a11yProps(index: number) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    // collection filters for owned nfts
    const [nftFilters, setNftFilters] = useState<{name: string, contract: string}[]>([]);
    const addNftFilter = (contract: string, name: string) => {
        if(nftFilters.map(i => i.contract).indexOf(contract) < 0){
            setNftFilters(oldFilters => [...oldFilters, {name, contract}]);
        }
    }
    const removeNftFilter = (contract: string) => {
        setNftFilters(oldFilters => oldFilters.filter(i => i.contract !== contract));
    }


    // collection filters for created raffles
    const [createdFilters, setCreatedFilters] = useState<CollectionFilter[]>([]);
    const addCreatedFilter = (contract: string, name: string) => {
        if(createdFilters.map(i => i.contract).indexOf(contract) < 0){
            setCreatedFilters(oldFilters => [...oldFilters, {name, contract}]);
        }
    }
    const removeCreatedFilter = (contract:string) => {
        setCreatedFilters(oldFilters => oldFilters.filter(i => i.contract !== contract));
    }


    // collection filters for entered raffles
    const [enteredFilters, setEnteredFilters] = useState<CollectionFilter[]>([]);
    const addEnteredFilter = (contract: string, name: string) => {
        if(enteredFilters.map(i => i.contract).indexOf(contract) < 0){
            setEnteredFilters(oldFilters => [...oldFilters, {name, contract}]);
        }
    }
    const removeEnteredFilter = (contract:string) => {
        setEnteredFilters(oldFilters => oldFilters.filter(i => i.contract !== contract));
    }

    // collections filters for watching raffles
    const [watchingFilters, setWatchingFilters] = useState<CollectionFilter[]>([]);
    const addWatchingFilter = (contract: string, name: string) => {
        if(watchingFilters.map(i => i.contract).indexOf(contract) < 0){
            setWatchingFilters(oldFilters => [...oldFilters, {name, contract}]);
        }
    }
    const removeWatchingFilter = (contract:string) => {
        setWatchingFilters(oldFilters => oldFilters.filter(i => i.contract !== contract));
    }


    // filters for min/max price
    const [priceRange, setPriceRange] = useState<{min: number, max: number} |  null>(null)
    const applyPriceFilter = (min: number, max: number) => {
        console.log(min, max)
        setPriceRange({min, max});
    }
    const removePriceFilter = () => {
        setPriceRange(null);
    }

    // filters for created raffles status
    const [createdStatusFilters, setCreatedStatusFilters] = useState<StatusFilter[]>([]);
    const addCreatedStatusFilter = (status:StatusFilter) => {
        if(!createdStatusFilters.includes(status)) setCreatedStatusFilters(oldFilters => [...oldFilters, status]);
    }
    const removeCreatedStatusFilter = (status:StatusFilter) => {
        setCreatedStatusFilters(oldFilters => oldFilters.filter(i => i !== status));
    }
    const resetCreatedStatusFilter = () => {
        setCreatedStatusFilters([]);
    }

    // filters for entered raffles status
    const [enteredStatusFilters, setEnteredStatusFilters] = useState<StatusFilter[]>([]);
    const addEnteredStatusFilter = (status:StatusFilter) => {
        if(!enteredStatusFilters.includes(status)) setEnteredStatusFilters(oldFilters => [...oldFilters, status]);
    }
    const removeEnteredStatusFilter = (status:StatusFilter) => {
        setEnteredStatusFilters(oldFilters => oldFilters.filter(i => i !== status));
    }
    const resetEnteredStatusFilter = () => {
        setEnteredStatusFilters([]);
    }

    // filters for watching raffles status
    const [watchingStatusFilters, setWatchingStatusFilters] = useState<StatusFilter[]>([]);
    const addWatchingStatusFilter = (status:StatusFilter) => {
        if(!watchingStatusFilters.includes(status)) setWatchingStatusFilters(oldFilters => [...oldFilters, status]);
    }
    const removeWatchingStatusFilter = (status:StatusFilter) => {
        setWatchingStatusFilters(oldFilters => oldFilters.filter(i => i !== status));
    }
    const resetWatchingStatusFilter = () => { setWatchingStatusFilters([]); }

    // sort order for the raffles
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrders[0].key);
    const handleSortOrderChange = (event:any) => {
        console.log(event.target.value)
        setSortOrder(event.target.value);
    }

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const toggleDrawer = () => setOpenDrawer(oldValue => !oldValue);

    const raffleTabBorderLeft = useMemo(() => {
        if(breakpoint === "sm" || breakpoint === "xs" || Number(currentTab === 4)){
            return 0;
        }
        return 1;
    }, [breakpoint, currentTab])

    if(noUser){
        return <NotFound searchValue={address as string} type="user" />
    } else {
        return (
            <>
            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={toggleDrawer}
                sx={{'.MuiDrawer-paper': {backgroundColor: alpha(Theme.palette.background.paper, 0.3)}}}>
                <ProfileFilters
                    currentTab={currentTab}
                    nftCollections={nftCollections}
                    rafflesCreatedCollections={
                        rafflesCreatedCollections
                    }
                    rafflesEnteredCollections={
                        rafflesEnteredCollections
                    }
                    rafflesWatchingCollections={
                        rafflesWatchingCollections
                    }
                    addNftFilter={addNftFilter}
                    addCreatedFilter={addCreatedFilter}
                    addEnteredFilter={addEnteredFilter}
                    addWatchingFilter={addWatchingFilter}
                    applyPriceFilter={applyPriceFilter}
                    addCreatedStatusFilter={addCreatedStatusFilter}
                    resetCreatedStatusFilter={resetCreatedStatusFilter}
                    addEnteredStatusFilter={addEnteredStatusFilter}
                    resetEnteredStatusFilter={resetEnteredStatusFilter}
                    addWatchingStatusFilter={addWatchingStatusFilter}
                    resetWatchingStatusFilter={resetWatchingStatusFilter}
                />
                <Paper sx={{py: 2, display: {xs: "block", md: "none"}}}>
                    <FormControl sx={{marginRight: "10px", mt: 2}} fullWidth focused={false} >
                        <InputLabel color="primary">Sort By:</InputLabel>
                        <Select
                            value={sortOrder}
                            label="Sort By:"
                            onChange={handleSortOrderChange}
                            sx={{width: "260px", textAlign: "left"}}
                        >
                            {SortOrders.map(i => {
                                return <MenuItem value={i.key}>{i.value}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Paper>
                <Button size="medium" variant="contained" onClick={toggleDrawer} sx={{mt: 2}}>Close</Button>
            </Drawer>
            
            <Grid container justifyContent={"center"} marginTop={4}>
                <EditProfile
                    open={showEditProfile}
                    handleClose={handleCloseEditModal}
                    userData={userData || {} as ProfileUser}
                    callback={fetchUser}
                />
                <RaffleItem 
                    open={showRaffleItem}
                    close={closeRaffleModal}
                    item={raffleItem}
                    callback={fetchUser}
                />
                <EnterRaffle 
                    open={showEnterRaffle}
                    close={closeEnterRaffleModal}
                    item={enterRaffleItem}
                    callback={fetchUser}
                />
                <Grid item xs={8} lg={3} xl={2}>
                    <Box display="flex">
                        <AccountCircle sx={{ fontSize: "100px" }} />

                        <Box textAlign="left" my="auto" ml="10px">
                            {userData ?
                            <Typography variant="h4" sx={{ fontWeight: 600 }}>
                                {userData.username.length > 13 ? userData.username.slice(0,5) + "..." + userData.username.slice(userData.username.length - 5) : userData.username}
                            </Typography>
                            : <Skeleton height={40} width={160}/>}
                            <Typography>{TruncateEthAddress(address)}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={11} sm={8} lg={6} xl={5} display="flex" justifyContent="space-between" mt={{xs: 3, lg: 0}}>
                    <Box my="auto">
                        <Typography fontSize={{xs: "14px", sm: "18px", md: "20px"}} sx={{ fontWeight: 600 }}>
                            CREATED
                        </Typography>
                        {userData ?
                        <Typography variant="h6">
                            {rafflesCreated.length}
                        </Typography>
                        : <Skeleton height={40} /> }
                    </Box>
                    <Box my="auto">
                        <Typography fontSize={{xs: "14px", sm: "18px", md: "20px"}} sx={{ fontWeight: 600 }}>
                            ENTERED
                        </Typography>
                        {userData ?
                        <Typography variant="h6">
                            {rafflesEntered.length}
                        </Typography>
                        : <Skeleton height={40} /> }
                    </Box>
                    <Box my="auto">
                        <Typography fontSize={{xs: "14px", sm: "18px", md: "20px"}} sx={{ fontWeight: 600 }}>
                            WON
                        </Typography>
                        {userData ?
                        <Typography variant="h6">
                            {rafflesWon.length}
                        </Typography>
                        : <Skeleton height={40} /> }
                    </Box>
                    {isUser && (
                        <Box my="auto">
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={handleOpenEditModal}
                            >
                                Edit Profile
                            </Button>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={10} lg={8} xl={7} display="flex" textAlign={"left"} justifyContent="space-between" marginTop={4}>
                    {userData ? (
                        <Grid container display={showAbout ? "flex" : "none"}>
                            <Grid item xs={2} lg={1}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: "18px",
                                            lg: "20px"
                                        },
                                        fontWeight: 600,
                                        textAlign: "right",
                                    }}
                                >
                                    About
                                </Typography>
                            </Grid>
                            <Grid item xs={10} lg={11}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: "14px",
                                            lg: "18px"
                                        },
                                        marginLeft: "20px",
                                        marginTop: {
                                            xs: "2px",
                                            lg: "5px"
                                        },
                                    }}
                                >
                                    {userData.about}
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : <Skeleton width={700} height={30} />}
                </Grid>
                <Grid item xs={10} lg={8} xl={7} display="flex" textAlign={"left"} justifyContent="space-between">
                    {userData ? (
                        <Grid container display={showWebsite ? "flex" : "none"}>
                            <Grid item xs={2} lg={1} textAlign="right">
                                <Language
                                    sx={{ fontSize: {xs: "30px", lg: "35px"}, textAlign: "right" }}
                                />
                            </Grid>
                            <Grid item xs={10} lg={11}>
                                <a href={userData.website} target="_blank" rel="noreferrer">
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: "14px",
                                                lg: "18px"
                                            },
                                            marginLeft: "20px",
                                            marginTop: "5px",
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        {userData.website}
                                    </Typography>
                                </a>
                            </Grid>
                        </Grid>
                    ) : <Skeleton width={700} height={30} />}
                </Grid>

                <Grid item xs={12} marginTop={4}>
                    <Grid container>
                        <Grid
                            item
                            xs={0}
                            md={3}
                            lg={2}
                            borderBottom={1}
                            borderColor="darkgrey"
                        ></Grid>
                        <Grid item xs={12} md={9} lg={10} borderBottom={1} borderColor="darkgrey">
                            <Box sx={{ width: "100%" }}>
                                <Tabs
                                    value={currentTab}
                                    onChange={handleTabChange}
                                    aria-label="basic tabs example"
                                    variant="fullWidth"
                                >
                                    <Tab
                                        sx={{ fontSize: {xs: "12px", md: "16px", lg: "18px"},
                                            "&.Mui-selected": {
                                                color: lighten(Theme.palette.primary.main, 0.3),
                                                background: lighten(Theme.palette.background.default, 0.05)
                                            } 
                                        }}
                                        icon={<Inventory />}
                                        iconPosition="start"
                                        label="Owned NFTs"
                                        value={0}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        sx={{ fontSize: {xs: "12px", md: "16px", lg: "18px"},
                                            "&.Mui-selected": {
                                                color: lighten(Theme.palette.primary.main, 0.3),
                                                background: lighten(Theme.palette.background.default, 0.05)
                                            } 
                                        }}
                                        icon={<Create />}
                                        iconPosition="start"
                                        label="Created"
                                        value={1}
                                        {...a11yProps(1)}
                                    />
                                    {isUser && (
                                        <Tab
                                            sx={{ fontSize: {xs: "12px", md: "16px", lg: "18px"},
                                                "&.Mui-selected": {
                                                    color: lighten(Theme.palette.primary.main, 0.3),
                                                    background: lighten(Theme.palette.background.default, 0.05)
                                                } 
                                            }}
                                            icon={<ConfirmationNumber />}
                                            iconPosition="start"
                                            label="Entered"
                                            value={2}
                                            {...a11yProps(2)}
                                        />
                                    )}
                                    <Tab
                                        sx={{ fontSize: {xs: "12px", md: "16px", lg: "18px"},
                                            "&.Mui-selected": {
                                                color: lighten(Theme.palette.primary.main, 0.3),
                                                background: lighten(Theme.palette.background.default, 0.05)
                                            } 
                                        }}
                                        icon={<Visibility />}
                                        iconPosition="start"
                                        label="Watching"
                                        value={3}
                                        {...a11yProps(3)}
                                    />
                                    <Tab
                                        sx={{ fontSize: {xs: "12px", md: "16px", lg: "18px"},
                                            "&.Mui-selected": {
                                                color: lighten(Theme.palette.primary.main, 0.3),
                                                background: lighten(Theme.palette.background.default, 0.05)
                                            },
                                            display: {
                                                xs: "none",
                                                lg: "block"
                                            }
                                        }}
                                        icon={<ShowChart />}
                                        iconPosition="start"
                                        label="Activity"
                                        value={4}
                                        {...a11yProps(4)}
                                    />
                                </Tabs>
                            </Box>
                        </Grid>
                        {currentTab !== 4 &&
                        <Grid item xs={2} md={3} lg={2} display={{xs: "none", md: "block"}}>
                            <ProfileFilters
                                currentTab={currentTab}
                                nftCollections={nftCollections}
                                rafflesCreatedCollections={
                                    rafflesCreatedCollections
                                }
                                rafflesEnteredCollections={
                                    rafflesEnteredCollections
                                }
                                rafflesWatchingCollections={
                                    rafflesWatchingCollections
                                }
                                addNftFilter={addNftFilter}
                                addCreatedFilter={addCreatedFilter}
                                addEnteredFilter={addEnteredFilter}
                                addWatchingFilter={addWatchingFilter}
                                applyPriceFilter={applyPriceFilter}
                                addCreatedStatusFilter={addCreatedStatusFilter}
                                resetCreatedStatusFilter={resetCreatedStatusFilter}
                                addEnteredStatusFilter={addEnteredStatusFilter}
                                resetEnteredStatusFilter={resetEnteredStatusFilter}
                                addWatchingStatusFilter={addWatchingStatusFilter}
                                resetWatchingStatusFilter={resetWatchingStatusFilter}
                            />
                        </Grid>
                        }
                        <Grid item xs={12} md={9} lg={currentTab === 4 ? 12 : 10} borderLeft={raffleTabBorderLeft} borderColor="darkgrey" minHeight="60vh">
                            {currentTab !== 4 &&
                            <Box margin={{xs: 1, md: 2, lg: 3}} marginTop={2} mb={{xs: 1, lg: 0}} textAlign="right" display="flex" justifyContent="space-between">
                                <TextField variant="outlined" label="Search" sx={{width: "500px"}} placeholder="Collection Name or TokenID" value={searchQuery} onChange={handleSearchChange}></TextField>
                                <Box display="flex" justifyContent="space-around">
                                    {currentTab > 0 &&
                                    <FormControl sx={{marginRight: "10px", display: {xs: "none", md: "block"}}}>
                                        <InputLabel>Sort By:</InputLabel>
                                        <Select
                                            value={sortOrder}
                                            label="Sort By:"
                                            onChange={handleSortOrderChange}
                                            sx={{width: "260px", textAlign: "left"}}
                                        >
                                            {SortOrders.map(i => {
                                                return <MenuItem value={i.key}>{i.value}</MenuItem>;
                                            })}
                                        </Select>
                                    </FormControl>
                                    }
                                    <IconButton sx={{color: "black", display: {xs: "none", lg: "block"}}} onClick={setLargeCards}>
                                        <ViewColumn sx={{margin: "auto", fontSize: "40px"}}/>
                                    </IconButton>
                                    <IconButton sx={{color: "black", display: {xs: "none", lg: "block"}}} onClick={setSmallCards}>
                                        <ViewModule sx={{margin: "auto", fontSize: "40px"}}/>
                                    </IconButton>
                                    <IconButton sx={{color: "black", display: {xs: "block", md: "none"}}} onClick={toggleDrawer}>
                                        <FilterAlt sx={{margin: "auto", fontSize: "40px"}}/>
                                    </IconButton>
                                </Box>
                            </Box>
                            }
                            <NftTab currentTab={currentTab} 
                                nfts={nfts} 
                                removeNftFilter={removeNftFilter} 
                                nftFilters={nftFilters} 
                                cardSize={cardSize} 
                                searchQuery={searchQuery}
                                isUser={isUser}
                                raffleItem={openRaffleModal} />
                            <RaffleTab currentTab={currentTab} 
                                index={1} 
                                raffles={rafflesCreated} 
                                removeFilter={removeCreatedFilter} 
                                filters={createdFilters} 
                                priceFilter={priceRange} 
                                resetPriceFilter={removePriceFilter} 
                                statusFilters={createdStatusFilters}
                                removeStatusFilter={removeCreatedStatusFilter}
                                userAddress={address as string}
                                sortOrder={sortOrder}
                                cardSize={cardSize}
                                searchQuery={searchQuery}
                                openEnterModal={openEnterRaffleModal} />
                            <RaffleTab currentTab={currentTab} 
                                index={2} 
                                raffles={rafflesEntered} 
                                removeFilter={removeEnteredFilter} 
                                filters={enteredFilters} 
                                priceFilter={priceRange} 
                                resetPriceFilter={removePriceFilter} 
                                statusFilters={enteredStatusFilters}
                                removeStatusFilter={removeEnteredStatusFilter}
                                userAddress={address as string}
                                sortOrder={sortOrder}
                                cardSize={cardSize}
                                searchQuery={searchQuery}
                                openEnterModal={openEnterRaffleModal} />
                            <RaffleTab currentTab={currentTab}
                                index={3}
                                raffles={rafflesWatching}
                                removeFilter={removeWatchingFilter}
                                filters={watchingFilters}
                                priceFilter={priceRange}
                                resetPriceFilter={removePriceFilter}
                                statusFilters={watchingStatusFilters}
                                removeStatusFilter={removeWatchingStatusFilter}
                                userAddress={address as string}
                                sortOrder={sortOrder}
                                cardSize={cardSize}
                                searchQuery={searchQuery}
                                openEnterModal={openEnterRaffleModal} />
                            <ActivityDataGrid
                                currentTab={currentTab}
                                index={4}
                                activity={activity}
                                title="Recent User Activity"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </>
        );
    }
}

export default Profile;