import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircleOutline, Error, OpenInNew } from "@mui/icons-material";
import { Box, Button, CircularProgress, FormControl, FormLabel, Modal, Slider, TextField, Typography } from "@mui/material";
import { formatEther } from "ethers";
import { useState } from "react";
import iRazAdapter from "../../adapters/iRazAdapter";
import { Raffle } from "../../interfaces/raffle";
import { GetTransactionUrl } from "../../utils/getTransactionUrl";
import { useFormik } from "formik";
import * as yup from "yup";
import Styles from "../../themes/styles";
import { toast } from "react-toastify";

interface EnterRaffleProps {
    item: Raffle | null;
    open: boolean;
    close: () => void;
    callback: () => void;
}

const EnterRaffle = ({ item, open, close, callback }: EnterRaffleProps) => {
    const { ModalStyle } = Styles();

    enum Steps {
        ENTER_INFO = 1,
        RAFFLE_TRANSACTION = 2,
        CREATED = 3,
        ERROR = 4
    }

    const [currentStep, setCurrentStep] = useState<Steps>(Steps.ENTER_INFO);
    const [transactionHash, setTransactionHash] = useState<string>("");

    const validationSchema = yup.object({
        spots: yup.number().min(1, "You must buy at least 1 ticket").required("You must buy at least 1 ticket")
    });

    const handleSubmit = async (values: any) => {
        setCurrentStep(Steps.RAFFLE_TRANSACTION);
        try{
            if(item){
               const success = await iRazAdapter.PurchaseEntry(item.raffleId, values.spots, function(){
                    // callback function
                });
                if(success){
                    setCurrentStep(Steps.CREATED);
                    setTransactionHash(success.hash);
                    setTimeout(() => {
                        CloseModal();
                    }, 5000)
                }
            } else {
                setCurrentStep(Steps.ERROR);
            }
            
        } catch(error:any){
            console.log(error.code)
            if (error.code === "ACTION_REJECTED"){
                setCurrentStep(Steps.ENTER_INFO)
            } else if(error.code === "INSUFFICIENT_FUNDS") {
                setCurrentStep(Steps.ENTER_INFO);
                CloseModal();
                toast.error("Insufficient funds to enter the Raffle.");
            } else {
                setCurrentStep(Steps.ERROR)
            }
        } 
    }

    const enterRaffleForm = useFormik({
        initialValues: {
            spots: 1
        },
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    })

    function CloseModal() {
        callback();
        enterRaffleForm.resetForm();
        setCurrentStep(Steps.ENTER_INFO);
        setTransactionHash("");
        close();
    }

    function ViewTransaction(){
        var url = GetTransactionUrl(transactionHash);
        window.open(url, "_blank");
    }

    if (item) {
        return (
            <Modal open={open} onClose={CloseModal}>
                <Box borderRadius="25px" sx={ModalStyle}>
                    <Box
                        borderBottom={1}
                        borderColor="darkgrey"
                        textAlign="center"
                    >
                        <Typography
                            variant="h4"
                            sx={{ fontSize: "40px", fontWeight: 600 }}
                            marginBottom={2}
                        >
                            Enter Raffle
                        </Typography>
                    </Box>
                    <Box
                        borderBottom={1}
                        paddingTop={1}
                        paddingBottom={1}
                        borderColor="darkgrey"
                        display="flex"
                    >
                        <img
                            src={item.image}
                            style={{
                                maxWidth: "80px",
                                marginRight: "10px",
                                borderRadius: "10px",
                            }}
                            alt=""
                        />
                        <div
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                            <Typography
                                sx={{ fontWeight: 600, fontSize: "22px" }}
                            >
                                {item.name}
                            </Typography>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "18px" }}
                            >
                                {item.collectionData.name}
                            </Typography>
                        </div>
                    </Box>
                    <Box borderBottom={1} borderColor="darkgrey" textAlign="center" py={2}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "20px",
                            }}
                        >
                            {currentStep === Steps.RAFFLE_TRANSACTION &&
                            <>
                                <CircularProgress color="primary" size={50}/><br />
                                Confirm the transaction in your browser
                            </>
                            }
                            {currentStep === Steps.ENTER_INFO &&
                            <>
                            <Box display="flex" p={2}>
                                <FormControl sx={{pr: 10, pl: 2, width: "60%"}}>
                                    <FormLabel>Amount of Tickets</FormLabel>
                                    <Slider
                                        aria-label="Entry Spots"
                                        defaultValue={1}
                                        value={enterRaffleForm.values.spots}
                                        step={1}
                                        marks
                                        min={1}
                                        max={item.spots - item.entryCount}
                                        onChange={(e, value) => enterRaffleForm.setFieldValue("spots", value)}
                                        valueLabelDisplay="auto"
                                    />
                                </FormControl>
                                <TextField label="Spots" sx={{width: "20%"}} type="number" id="spots" name="spots" value={enterRaffleForm.values.spots} InputProps={{inputProps: {min: 1, max: item.spots - item.entryCount}}} onChange={enterRaffleForm.handleChange} />
                            </Box>
                            <Typography>Press the button below to enter the raffle</Typography>
                            </>
                            }
                            {currentStep === Steps.CREATED && 
                            <>
                                <CheckCircleOutline sx={{fontSize: "70px"}} color="primary"/><br />
                                You have entered the Raffle!<br />
                                <Box
                                    display="flex"
                                    onClick={() => ViewTransaction()}
                                    sx={{ cursor: "pointer" }}
                                    justifyContent="center"
                                    mt={1}
                                >
                                    <Typography sx={{ textDecoration: "underline" }}>
                                        View Transaction
                                    </Typography>
                                    <OpenInNew sx={{ my: "auto" }} />
                                </Box>
                            </>}
                            {currentStep === Steps.ERROR &&
                            <>
                                <Error sx={{fontSize: "70px"}} color="error"/><br />
                                An error has occured when entering the Raffle
                            </>}
                        </Typography>
                    </Box>
                    {currentStep === Steps.ENTER_INFO &&
                    <Box textAlign="center" mt={2}>
                        <Button 
                            variant="contained" 
                            size="medium" sx={{fontSize: "20px"}}
                            onClick={() => enterRaffleForm.submitForm()}
                        >
                            Enter Raffle &nbsp;<span style={{fontWeight: 600}}>{Number(formatEther(item.entryPrice)) * enterRaffleForm.values.spots} <FontAwesomeIcon icon={faEthereum} /></span>
                        </Button>
                    </Box>
                    }
                </Box>
            </Modal>
        );
    } else return <></>;
};

export default EnterRaffle;