import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EvmNft } from "@moralisweb3/evm-utils";
import { CheckCircleOutline, OpenInNew } from "@mui/icons-material";
import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import { ethers } from "ethers";
import { useFormik } from "formik";
import { useEffect, useState, useContext } from "react";
import iRazAdapter from "../../adapters/iRazAdapter";
import { GetNftImage } from "../../utils/nftUtils";
import * as yup from "yup";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import add from "date-fns/add";
import { RaffleDuration } from "../../interfaces/types";
import { GetTransactionUrl } from "../../utils/getTransactionUrl";
import Styles from "../../themes/styles";
import { AppContext } from "../../context/AppContext";

interface RaffleItemProps {
    item: EvmNft | null;
    open: boolean;
    close: () => void;
    callback: () => void;
}

const RaffleItem = ({item, open, close, callback}:RaffleItemProps) => {
    const [listingFee, setListingFee] = useState<number>(0.012);
    const {Theme, user, isPremium} = useContext(AppContext);
    const { ModalStyle } = Styles();

    const [showTimeInput, setShowTimeInput] = useState<boolean>(true);

    enum Steps {
        ENTER_INFO = 1,
        CHECK_APPROVAL = 2,
        RAFFLE_TRANSACTION = 3,
        CREATED = 4
    }

    const [currentStep, setCurrentStep] = useState<Steps>(Steps.ENTER_INFO);
    const [transactionHash, setTransactionHash] = useState<string>("");

    const initialValues = {
        entryPrice: 0.001,
        spots: 2,
        premium: false,
        endTime: add(new Date(), {days: 1}).getTime(),
        duration: "1 Day"
    }

    const validationSchema = yup.object({
        entryPrice: yup.number().min(0.00001, "The entry price must be at least 0.00001 ETH").required("You must enter the entry price"),
        spots: yup.number().min(2, "There must be at least 2 spots").max(100000, "You cannot have more than 1000000 spots"),
        endTime: yup.number().required("You must specify an end time").when("duration", {
            is: (duration: RaffleDuration) => duration === "When all spots fill",
            then: (schema) => schema.min(0, "You cannot enter a value less than 0"),
            otherwise: (schema) => schema.min(add(new Date(), {minutes: 30}).getTime(), "The raffle must last at least 30 minutes")
        }),
    });

    const handleSubmit = async (values:any) => {
        try{
            if(item && item.tokenAddress){
                setCurrentStep(Steps.CHECK_APPROVAL);
                var nftContract = String(item.tokenAddress);
                const approved = await iRazAdapter.CheckApprovalForAll(nftContract, user?.ethAddress as string);
                if(approved){
                    setCurrentStep(Steps.RAFFLE_TRANSACTION);
                    var time = Math.floor(values.endTime / 1000);
                    const created = await iRazAdapter.CreateRaffle(nftContract, item.tokenId.toString(), values.spots, values.entryPrice, values.premium, time);
                    setTransactionHash(created.hash);
                    setCurrentStep(Steps.CREATED);
                    setTimeout(() => {
                        CloseModal();
                    }, 5000);
                }
            }
        } catch(error){
            //console.log("error", error)
            setCurrentStep(Steps.ENTER_INFO);
        }
    }

    const handleDurationChange = (e: SelectChangeEvent) => {
        let value = e.target.value as RaffleDuration;
        if(value){
            raffleForm.setErrors({"endTime": ""});
            switch(value){
                case "1 Day":
                    raffleForm.setFieldValue(
                        "endTime",
                        add(new Date(), {days: 1}).getTime()
                    );
                    setShowTimeInput(true);
                    break;
                case "3 Days":
                    raffleForm.setFieldValue(
                        "endTime",
                        add(new Date(), {days: 3}).getTime()
                    );
                    setShowTimeInput(true);
                    break;
                case "7 Days":
                    raffleForm.setFieldValue(
                        "endTime",
                        add(new Date(), {days: 7}).getTime()
                    );
                    setShowTimeInput(true);
                    break;
                case "3 Months":
                    raffleForm.setFieldValue(
                        "endTime",
                        add(new Date(), {months: 3}).getTime()
                    );
                    setShowTimeInput(true);
                    break;
                case "6 Months":
                    raffleForm.setFieldValue(
                        "endTime",
                        add(new Date(), {months: 6}).getTime()
                    );
                    setShowTimeInput(true);
                    break;
                case "When all spots fill":
                    raffleForm.setFieldValue("endTime", 0);
                    setShowTimeInput(false);
                    break;
            }
            raffleForm.setFieldValue("duration", e.target.value);
        }
    }

    const handleTimePickerChange = (value: number | null) => {
        if(value){
            raffleForm.setFieldValue("endTime", new Date(value).getTime());
        }
    }

    const raffleForm = useFormik({
        initialValues: initialValues,
        onSubmit: handleSubmit,
        validationSchema: validationSchema,
        validateOnBlur: false
    });

    function IncomeAmount(){
        const income = raffleForm.values.entryPrice * raffleForm.values.spots;
        const siteFee = (raffleForm.values.entryPrice * raffleForm.values.spots) * 0.02;
        return income - siteFee - listingFee;
    }

    useEffect(() => {
        async function FetchListingFee(){
            var fee = await iRazAdapter.GetListingFee();
            var eth = ethers.formatEther(fee);
            setListingFee(Number(eth));
        }

        FetchListingFee();
    }, [user])

    function CloseModal(){
        raffleForm.resetForm();
        callback();
        setCurrentStep(Steps.ENTER_INFO);
        setTransactionHash("");
        close();
    }

    function ViewTransaction(){
        var url = GetTransactionUrl(transactionHash);
        window.open(url, "_blank");
    }
    
    if(item){
    return (
        <Modal open={open} onClose={CloseModal}>
            <Box borderRadius="25px" sx={ModalStyle}>
                <Box borderBottom={1} borderColor="darkgrey" textAlign="center">
                    <Typography
                        variant="h4"
                        sx={{ fontSize: "40px", fontWeight: 600 }}
                        marginBottom={2}
                    >
                        Raffle Item
                    </Typography>
                </Box>
                <Box borderBottom={1} paddingTop={1} paddingBottom={1} borderColor="darkgrey" display="flex">
                    <img src={GetNftImage(item)} style={{maxWidth: "80px", marginRight: "10px", borderRadius: "10px"}} alt=""/>
                    <div style={{marginTop: "auto", marginBottom: "auto"}}>
                        <Typography sx={{fontWeight: 600, fontSize: "22px"}}>{item.name} #{item.tokenId}</Typography>
                        <Typography sx={{fontWeight: 500, fontSize: "18px"}}>{item.name}</Typography>
                    </div>
                </Box>
                {currentStep === Steps.ENTER_INFO &&
                <>
                <Box borderBottom={1} paddingTop={2} paddingBottom={2} borderColor="darkgrey" display="flex" justifyContent="center">
                    <Grid container>
                        <Grid item xs={6} paddingRight={1}>
                            <TextField variant="outlined" placeholder="0.1" label="Entry Price (ETH)" type="number" value={raffleForm.values.entryPrice} 
                            fullWidth
                            name="entryPrice"
                            onChange={raffleForm.handleChange}
                            error={raffleForm.touched.entryPrice && Boolean(raffleForm.errors.entryPrice)}
                            helperText={raffleForm.errors.entryPrice}
                            InputProps={{
                                startAdornment: (
                                    <FontAwesomeIcon style={{ fontSize: "22px", marginRight: "10px"}} icon={faEthereum} />
                                ),
                            }}
                            inputProps={{
                                min: 0,
                                max: 1000,
                                step: 0.01
                            }}></TextField>
                        </Grid>
                        <Grid item xs={6} paddingLeft={1}>
                            <TextField variant="outlined" placeholder="10" label="Amount of Spots" type="number" 
                            fullWidth
                            name="spots" 
                            error={raffleForm.touched.spots && Boolean(raffleForm.errors.spots)}
                            helperText={raffleForm.errors.spots}
                            value={raffleForm.values.spots} 
                            onChange={raffleForm.handleChange}
                            inputProps={{min: 2, max: 100000}}></TextField>
                        </Grid>
                    </Grid>
                </Box>
                <Box borderBottom={1} py={2} borderColor="darkgrey" display="flex" justifyContent="center">
                    <Grid container>
                        <Grid item xs={showTimeInput ? 6 : 12} pr={1}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={raffleForm.values.duration}
                                label="Duration"
                                onChange={handleDurationChange}
                            >
                                <MenuItem value={"1 Day"}>1 Day</MenuItem>
                                <MenuItem value={"3 Days"}>3 Days</MenuItem>
                                <MenuItem value={"7 Days"}>7 Days</MenuItem>
                                <MenuItem value={"3 Months"}>3 Months</MenuItem>
                                <MenuItem value={"6 Months"}>6 Months</MenuItem>
                                <MenuItem value={"When all spots fill"}>When all spots fill</MenuItem>
                            </Select>
                        </FormControl>
                        </Grid>
                        {showTimeInput &&
                        <Grid item xs={6} pl={1}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Raffle End Time"
                                    value={raffleForm.values.endTime}
                                    onChange={(value) => handleTimePickerChange(value)}
                                />  
                                {raffleForm.touched.endTime && Boolean(raffleForm.errors.endTime) &&
                                <FormHelperText>{raffleForm.errors.endTime as String}</FormHelperText>
                                }
                            </LocalizationProvider>
                        </Grid>
                        }
                    </Grid>
                </Box>
                {isPremium && false &&
                <Box borderBottom={1} paddingLeft={1} paddingBottom={2} borderColor="darkgrey">
                    <Typography sx={{fontWeight: 600, fontSize: "22px", paddingTop: "10px"}}>Premium Only</Typography>
                    <FormControlLabel control={<Switch />} label="Premium Raffle"
                        name="premium"
                        checked={raffleForm.values.premium}
                        onChange={raffleForm.handleChange} />
                </Box>
                }
                </>
                }
                {(currentStep === Steps.CHECK_APPROVAL || currentStep === Steps.RAFFLE_TRANSACTION) &&
                <Box borderBottom={1} padding={2} borderColor="darkgrey" textAlign="center">
                    <Typography sx={{fontWeight: 600, fontSize: "22px", marginBottom: "15px"}}>
                        {currentStep === Steps.CHECK_APPROVAL ? 
                        "Checking Approval for All...":
                        "Creating the Raffle..."}
                        </Typography>
                    <CircularProgress color="primary" size={50}/>
                    <Typography sx={{fontWeight: 500, fontSize: "20px", marginTop: "15px"}}>Confirm the transaction</Typography>
                </Box>}
                {currentStep === Steps.CREATED &&
                <Box borderBottom={1} padding={2} borderColor="darkgrey" textAlign="center">
                    <CheckCircleOutline sx={{fontSize: "70px"}} color="primary"/>
                    <Typography sx={{fontWeight: 600, fontSize: "24px"}}>Raffle Created</Typography>
                    <Box
                        display="flex"
                        onClick={() => ViewTransaction()}
                        sx={{ cursor: "pointer" }}
                        justifyContent="center"
                        mt={1}
                    >
                        <Typography sx={{ textDecoration: "underline" }}>
                            View Transaction
                        </Typography>
                        <OpenInNew sx={{ my: "auto" }} />
                    </Box>
                </Box>}
                <Box borderBottom={1} padding={2} paddingTop={1} paddingLeft={1} borderColor="darkgrey">
                    <Typography sx={{fontWeight: 600, fontSize: "22px"}}>Raffle Cost Breakdown</Typography>
                    <Box display="flex" justifyContent="space-between">
                        <Typography sx={{fontWeight: 500, fontSize: "20px"}}>Entry Price x Spots</Typography>
                        <Typography sx={{fontWeight: 500, fontSize: "20px", color: Theme.palette.success.main}}>+ {(raffleForm.values.entryPrice * raffleForm.values.spots).toFixed(4)} <FontAwesomeIcon icon={faEthereum}/></Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Typography sx={{fontWeight: 500, fontSize: "20px"}}>Site Fee (2%)</Typography>
                        <Typography sx={{fontWeight: 500, fontSize: "20px", color: Theme.palette.error.main}}>- {((raffleForm.values.entryPrice * raffleForm.values.spots) * 0.02).toFixed(4)} <FontAwesomeIcon icon={faEthereum}/></Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" paddingBottom={1} borderBottom={1} borderColor="darkgrey">
                        <Typography sx={{fontWeight: 500, fontSize: "20px"}}>Listing Fee</Typography>
                        <Typography sx={{fontWeight: 500, fontSize: "20px", color: Theme.palette.error.main}}>- {listingFee.toFixed(4)} <FontAwesomeIcon icon={faEthereum}/></Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" paddingTop={1}>
                        <Typography sx={{fontWeight: 600, fontSize: "20px"}}>Income</Typography>
                        <Typography sx={{fontWeight: 500, fontSize: "20px"}}>{IncomeAmount().toFixed(4)} <FontAwesomeIcon icon={faEthereum}/></Typography>
                    </Box>
                </Box>
                {currentStep === Steps.ENTER_INFO &&
                <Box padding={3} paddingBottom={0} textAlign="center">
                    <Button size="medium" variant="contained" onClick={raffleForm.submitForm}>Create Raffle</Button>
                </Box>
                }
            </Box>
        </Modal>
    );
    } else return <></>
}

export default RaffleItem;