import { ContentCopy } from "@mui/icons-material";
import { Alert, Box, Button, ClickAwayListener, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, styled, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProfileUser } from "../../interfaces/user";
import { useFormik } from "formik";
import * as yup from "yup";
import UserApi from "../../api/user";
import Styles from "../../themes/styles";
import { GetSocialMediaLabel, GetSocialMediaUsername, GetSocialWebsite } from "../../utils/socialMediaHelper";
import { AppContext } from "../../context/AppContext";

interface EditProfileProps {
    open: boolean;
    handleClose: VoidFunction;
    userData: ProfileUser;
    callback: VoidFunction;
}

const SectionHeader = styled(Typography)({
    fontSize: "24px",
    fontWeight: 600,
    marginTop: "10px",
});

const EditProfile = ({open, handleClose, userData, callback}:EditProfileProps) => {
    const { user } = useContext(AppContext);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const { ModalStyle } = Styles();

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [usernameLabel, setUsernameLabel] = useState<string>("Username");

    const initialValues =  {
        username: userData.username,
        about: userData.about || "",
        website: GetSocialWebsite(userData.website) || "",
        socialLabel: GetSocialMediaLabel(userData.website),
        socialUsername: GetSocialMediaUsername(userData.website)
    }

    const validationSchema = yup.object({
        username: yup
            .string()
            .min(4, "Username must be 4 or more characters")
            .required("Username is required"),
        about: yup.string().max(300, "About can only be a max of 300 characters").optional(),
        website: yup.string().max(80, "Max of 80 characters").optional()
    });

    const handleSubmit = async (values:any) => {
        setErrorMessage(null);
        setSuccessMessage(null);
        if(user){
            var usernameAvailable = true;

            let newUserData: {[k: string]: any} = {};
            if(user.username !== values.username){
                try{
                    await UserApi.checkUsername(values.username);
                    newUserData.username = values.username
                } catch(error){
                    usernameAvailable = false;
                    userForm.setErrors({username: "This username is in use"});
                }
            }
            if(user.about !== values.about){
                newUserData.about = values.about;
            }
            await UserApi.setSocial(values.website, values.socialUsername);

            if(usernameAvailable){
                try {
                    await UserApi.update(values.username, values.about);
                    callback();
                    setSuccessMessage(
                        "Updated your user profile successfully!"
                    );
                    setTimeout(() => {
                        handleFormClose();
                    }, 1500);
                } catch (error) {
                    setErrorMessage("Error updating your user profile");
                }
            }
        } else {
            alert("not authed error! you should not see this panel!")
        }
    }

    const userForm = useFormik({
        initialValues: initialValues,
        onSubmit: handleSubmit,
        validationSchema: validationSchema,
        validateOnChange: false
    });

    const resetValues = () => {
        userForm.setFieldValue("username", userData.username);
        userForm.setFieldValue("about", userData.about);
        userForm.setFieldValue("website", GetSocialWebsite(userData.website));
        userForm.setFieldValue("socialLabel", GetSocialMediaLabel(userData.website));
        userForm.setFieldValue("socialUsername", GetSocialMediaUsername(userData.website));
    }

    useEffect(() => {
        resetValues();
    }, [open]);

    useEffect(() => {
        if(userForm.values.website === "discord"){
            setUsernameLabel("Server Invite Code");
        } else {
            setUsernameLabel("Username");
        }
        userForm.setFieldValue("socialUsername", "");
    }, [userForm.values.website])

    const {address} = useParams();

    const copyAddress = () => {
        navigator.clipboard.writeText(address as string);
        handleTooltipOpen();
        setTimeout(() => {
            handleTooltipClose();
        }, 2000)
    }

    const handleTooltipClose = () => {
        setShowTooltip(false);
    }

    const handleTooltipOpen = () => {
        setShowTooltip(true);
    }

    const handleFormClose = () => {
        userForm.setErrors({});
        resetValues();
        setErrorMessage(null);
        setSuccessMessage(null);
        handleClose();
    }

    return (
        <Modal open={open} onClose={handleFormClose}>
            <Box borderRadius="25px" sx={ModalStyle}>
                {errorMessage && (
                    <Alert severity="error" sx={{ marginBottom: "10px" }}>
                        {errorMessage}
                    </Alert>
                )}
                {successMessage && (
                    <Alert severity="success" sx={{ marginBottom: "10px" }}>
                        {successMessage}
                    </Alert>
                )}
                <Box borderBottom={1} borderColor="darkgrey" textAlign="center">
                    <Typography
                        variant="h4"
                        sx={{ fontSize: "40px", fontWeight: 600 }}
                        marginBottom={2}
                    >
                        Your Profile
                    </Typography>
                </Box>
                <Box borderBottom={1} borderColor="darkGrey">
                    <SectionHeader>Wallet Address</SectionHeader>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{ marginTop: "auto", marginBottom: "auto", fontSize: {xs: "12px", md: "16px"} }}
                        >
                            {address}
                        </Typography>
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={showTooltip}
                                arrow
                                placement="top"
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied to Clipboard"
                            >
                                <IconButton onClick={copyAddress}>
                                    <ContentCopy></ContentCopy>
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>
                    </div>
                </Box>
                <Box borderBottom={1} borderColor="darkgrey" paddingBottom={2}>
                    <SectionHeader>Username</SectionHeader>
                    <TextField
                        fullWidth
                        name="username"
                        value={userForm.values.username}
                        onChange={userForm.handleChange}
                        helperText={userForm.errors.username}
                        error={
                            userForm.touched.username &&
                            Boolean(userForm.errors.username)
                        }
                    />
                </Box>
                <Box borderBottom={1} borderColor="darkgrey" paddingBottom={2}>
                    <SectionHeader>About</SectionHeader>
                    <TextField
                        inputProps={{ maxLength: 300 }}
                        fullWidth
                        multiline
                        rows={4}
                        name="about"
                        placeholder="About yourself..."
                        value={userForm.values.about}
                        onChange={userForm.handleChange}
                        helperText={userForm.errors.about}
                        error={
                            userForm.touched.about &&
                            Boolean(userForm.errors.about)
                        }
                    />
                </Box>
                <Box borderColor="darkgrey" paddingBottom={2}>
                    <SectionHeader>Social</SectionHeader>
                    <FormControl fullWidth sx={{my: 2}}>
                        <InputLabel id="select-social-label">Website</InputLabel>
                        <Select
                            labelId="select-social-label"
                            value={userForm.values.website}
                            onChange={userForm.handleChange}
                            label="Website"
                            name="website"
                            id="website"
                        >
                            <MenuItem value={""}>None</MenuItem>
                            <MenuItem value={"twitter"}>Twitter</MenuItem>
                            <MenuItem value={"instagram"}>Instagram</MenuItem>
                            <MenuItem value={"discord"}>Discord</MenuItem>
                            <MenuItem value={"tiktok"}>TikTok</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label={usernameLabel}
                        inputProps={{ maxLength: 80 }}
                        fullWidth
                        name="socialUsername"
                        placeholder="eg. calmix"
                        value={userForm.values.socialUsername}
                        onChange={userForm.handleChange}
                        helperText={userForm.errors.socialUsername}
                        error={
                            userForm.touched.socialUsername &&
                            Boolean(userForm.errors.socialUsername)
                        }
                    />
                </Box>
                <Box textAlign="center">
                    <Button
                        size="medium"
                        variant="contained"
                        onClick={userForm.submitForm}
                    >
                        Save Profile
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default EditProfile;