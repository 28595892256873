import { alpha, Box, Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";

interface InfoBoxProps {
    title: string;
    value: number;
}

const InfoBox = ({title, value}:InfoBoxProps) => {
    const {Theme} = useContext(AppContext);

    return (
        <Box 
            sx={{backgroundColor: alpha(Theme.palette.primary.main, 0.7)}} 
            display="flex" 
            justifyContent="space-between" 
            p={1}
            borderRadius="5px"
        >
            <Typography fontSize="14px" color="white">
                {title}
            </Typography>
            <Typography fontSize="14px" color="white" fontWeight={600}>
                {value}
            </Typography>
        </Box>
    )
} 

export default InfoBox;