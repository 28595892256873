import { Image, Language, Refresh, Twitter, Verified, Visibility } from "@mui/icons-material";
import { Box, Button, Chip, Grid, IconButton, Skeleton, Typography, lighten } from "@mui/material";
import InfoBox from "../InfoBox";
import Opensea from "../../images/opensea.svg";
import { useState, useContext } from "react";
import { Collection } from "../../interfaces/collection";
import CollectionApi from "../../api/collection";
import { toast } from "react-toastify";
//import Moralis from "moralis";
import { AppContext } from "../../context/AppContext";
import {useMemo} from "react";
import MuiMarkdown from "mui-markdown";
import CollectionFloorPrice from "../badges/CollectionFloorPrice";

interface CollectionHeaderProps {
    collection: Collection | null;
    rafflesOpenCount: number;
    lowestCost: number;
    watching: boolean;
    toggleWatching: VoidFunction;
    verified: boolean;
}

const CollectionHeader = ({collection, rafflesOpenCount, lowestCost, watching, toggleWatching, verified}: CollectionHeaderProps) => {
    const {Theme, breakpoint, openConnect, isAuthenticated} = useContext(AppContext);

    const maxDescriptionLength = 470;

    const [readMore, setReadMore] = useState<boolean>(false);

    const ShowMore = () => {
        setReadMore(true);
    }

    const ShowLess = () => {
        setReadMore(false);
    }

    async function RefreshMeta(){
        if(collection){
            toast.info("This collection has been queued for an update! Check back soon...");
            // cloud function to refresh nft metadata
            CollectionApi.refreshMeta(collection.nftContract);
        }
    }

    const AboutText = () => {
        if(collection){
            if(collection.description && collection.description.length > maxDescriptionLength && !readMore){
                return collection.description.slice(0, maxDescriptionLength) + "...";
            }
    
            if(!collection.description || collection.description.length === 0){
                return "No description provided.";
            }
    
            return collection.description;
        }
        return "";
    }

    const ReadMoreButton = () => {
        if(collection && collection.description && collection.description.length > maxDescriptionLength) {
            if(readMore){
                return <Button variant="contained" size="small" sx={{height: "fit-content", my: "auto"}} onClick={ShowLess}>Read Less</Button>
            }
            return <Button variant="contained" size="small" sx={{height: "fit-content", my: "auto"}} onClick={ShowMore}>Read More</Button>
        }

        return <></>
    }

    const CollectionImage = useMemo(() => {
        if(collection && collection.image){
            return <img src={collection.image} style={{maxWidth: "250px", borderRadius: "25px", marginRight: breakpoint === "xs" || breakpoint === "sm" ? 0 : "20px", marginTop: "auto", marginBottom: "auto"}} alt=""/>
        }

        return <Image sx={{ fontSize: "250px", marginRight: {xs: 0, md: "20px"}, my: "auto" }} />
    }, [breakpoint, collection]);

    async function TryWatch(){
        if(isAuthenticated){
            const currentWatching = watching;

            if(collection){
                toggleWatching();

                if(currentWatching){
                    collection.watchCount --;
                } else {
                    collection.watchCount ++;
                }

                try{   
                    await CollectionApi.toggleWatch(collection.nftContract);
                } catch(error:any){
                    toggleWatching();

                    if(currentWatching){
                        collection.watchCount ++;
                    } else {
                        collection.watchCount --;
                    }
                }
            }
        } else {
            openConnect();
        }
    }

    return (
    <Grid item xs={12} lg={10} xl={8} my={2} display="flex" justifyContent="space-between">
        <Box display={{xs: "none", md: "block"}} my="auto">
            {collection ? CollectionImage : <Skeleton width={200} height={200} />}
        </Box>
        <Grid container p={{xs: 1, lg: 2}} my="auto" mx={{xs: "auto", lg: 0}} flexGrow={1}>
            <Grid item xs={12} md={6} mb={2} sx={{height: "fit-content"}}>
                <Box display={{xs: "block", md: "none"}}>
                    {collection ? CollectionImage : <Skeleton width={200} />}
                </Box>
                <Typography variant="h5" sx={{ fontWeight: 600, fontSize: "38px" }} textAlign={{xs: "center", lg: "left"}}>
                    {collection && collection.name ? collection.name : <Skeleton width={300} />}{verified && <Verified sx={{color: lighten(Theme.palette.primary.main, 0.3), ml: 1, fontSize: "24px", my: "auto"}} />}
                </Typography>
                {collection ?
                <Typography fontSize={18} textAlign={{xs: "center", lg: "left"}}>
                    By: <Chip variant="filled" color="primary" label={collection.name} sx={{borderRadius: "10px"}} /> <CollectionFloorPrice floorPrice={collection.floorPrice} />
                </Typography>
                : <Skeleton width={200} /> }
                <Grid container rowSpacing={2} columnSpacing={2} justifyContent={{xs: "center", lg: "left"}} mt={2}>
                    <Grid item xs={6} height="fit-content">
                        {collection ? <InfoBox title="RAFFLES" value={collection.raffleCount} /> : <Skeleton /> }
                    </Grid>
                    <Grid item xs={6} height="fit-content">
                        {collection ? <InfoBox title="OPEN" value={rafflesOpenCount} /> : <Skeleton /> }
                    </Grid>
                    <Grid item xs={6} height="fit-content">
                        {collection ? <InfoBox title="LOWEST COST" value={lowestCost} /> : <Skeleton /> }
                    </Grid>
                    <Grid item xs={6} height="fit-content">
                        {collection ? <InfoBox title="TOTAL VOL" value={Math.round(collection.totalVolume * 100000) / 100000} /> : <Skeleton /> }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box textAlign="left" paddingLeft={{xs: 0, md: "40px"}}>
                    <Typography fontSize={20} fontWeight={600}>
                        Description
                    </Typography>
                    {collection ?
                     <Typography fontSize={16} lineHeight="17px">
                        <MuiMarkdown>
                            {AboutText()}
                        </MuiMarkdown>
                    </Typography>
                    : <Skeleton width={600} height={40} />}
                </Box>
                <Box display="flex" mt={1} paddingLeft={{xs: 0, md: "40px"}}>
                    <ReadMoreButton />
                    {collection && collection.twitter &&
                    <IconButton sx={{maxWidth: "50px"}}>
                        <a href={`https://twitter.com/${collection.twitter}`} target="_blank" rel="noreferrer">
                            <Twitter />
                        </a>
                    </IconButton>}
                    {collection && collection.image && 
                    <IconButton sx={{maxWidth: "50px"}}>
                        <a href={`https://opensea.io/assets/ethereum/${collection.nftContract}`} target="_blank" rel="noreferrer">
                            <img src={Opensea} style={{maxWidth: "25px"}} alt=""/>
                        </a>
                    </IconButton>}
                    {false &&
                    <IconButton sx={{maxWidth: "50px"}}>
                        <Language />
                    </IconButton>}
                    <IconButton sx={{maxWidth: "50px"}} onClick={RefreshMeta}>
                        <Refresh />
                    </IconButton>
                    {collection ? <Button variant="contained" size="small" sx={{height: "fit-content", my: "auto", ml: 1}} onClick={() => TryWatch()}><Visibility />&nbsp;{watching ? "Un-watch" : "Watch"} &nbsp;<span style={{fontWeight: 600, fontSize: "14px", marginLeft: "5px"}}>{collection.watchCount}</span></Button> : <Skeleton width={150} /> }
                </Box>
            </Grid>            
        </Grid>
    </Grid>
    );
}

export default CollectionHeader;