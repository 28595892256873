import { Breakpoint, Theme } from "@mui/material";
import React, { useEffect, useState } from "react";
import iRazAdminAdapter from "../adapters/iRazAdminAdapter";
import { LightTheme } from "../themes/lightTheme";
import { DarkTheme } from "../themes/theme";
import UserApi from "../api/user";
import NotificationApi from "../api/notification";
import { Notification } from "../interfaces/notification";
import { useWidth } from "../hooks/useWidth";
import StatsApi from "../api/stats";
import { SiteStats } from "../interfaces/siteStats";
import axios from "axios";
import { LoginWithMetamask } from "../utils/authUtils";
import { User } from "../interfaces/user";
import iRazPremiumAdapter from "../adapters/iRazPremiumAdapter";
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { useAccount, useConnect, useSignMessage, useDisconnect } from 'wagmi';
import AuthApi from "../api/auth";

interface IAppContext {
    isAuthenticated: boolean;
    setIsAuthenticated: (value: boolean) => void;
    user: User | null;
    isAdmin: boolean;
    isPremium: boolean;
    Theme: Theme;
    SetDarkMode: () => void;
    SetLightMode: () => void;
    darkMode: boolean;
    notifications: Notification[];
    SeenNotifications: () => void;
    RefreshNotifications: () => void;
    breakpoint: Breakpoint;
    showConnect: boolean;
    openConnect: () => void;
    closeConnect: () => void;
    siteStats: SiteStats;
    usd: number;
    statsLoaded: boolean;
    handleAuth: (provider: any, callback: VoidFunction, stopPending: VoidFunction) => void;
    logout: () => void;
}

export const AppContext = React.createContext<IAppContext>({
    isAuthenticated: false,
    setIsAuthenticated: function(): void{},
    user: null,
    isAdmin: false,
    isPremium: false,
    Theme: DarkTheme,
    SetDarkMode: function(): void{},
    SetLightMode: function(): void{},
    darkMode: true,
    notifications: [],
    SeenNotifications: function(): void{},
    RefreshNotifications: function(): void{},
    breakpoint: "xl",
    showConnect: false,
    openConnect: function(): void{},
    closeConnect: function(): void{},
    siteStats: {} as SiteStats,
    usd: 0,
    statsLoaded: false,
    handleAuth: function(provider:any, callback:VoidFunction, stopPending:VoidFunction): void{},
    logout: function(): void{},
});


interface Props {
    children: React.ReactNode;
}

export const AppProvider: React.FC<Props> = ({children}) => {
    const [user, setUser] = useState<User | null>(null);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem("SessionToken") !== null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isPremium, setIsPremium] = useState<boolean>(false);
    const [darkMode, setDarkMode] = useState<boolean>(localStorage.getItem("theme") !== "light");
    const [Theme, setTheme] = useState<Theme>(localStorage.getItem("theme") !== "light" ? DarkTheme : LightTheme);
    const breakpoint = useWidth();
    const [siteStats, setSiteStats] = useState<SiteStats>({} as SiteStats);
    const [usd, setUsd] = useState<number>(0);
    const [statsLoaded, setStatsLoaded] = useState(false);

    const [showConnect, setShowConnect] = useState<boolean>(false);
    const openConnect = () => setShowConnect(true);
    const closeConnect = () => setShowConnect(false);

    // wagmi connector hooks
    const { connectAsync } = useConnect()
    const { disconnectAsync } = useDisconnect()
    const { isConnected } = useAccount()
    const { signMessageAsync } = useSignMessage()

    const LoginWithWalletConnect = async() => {
        if (isConnected) {
            await disconnectAsync();
        }

        const { account, chain } = await connectAsync({
            connector: new WalletConnectConnector({
                options: {
                    projectId: process.env.REACT_APP_WC_PROJECT_ID as string, showQrModal: true
                },
            }),
        })

        const userData = { address: account, chain: chain.id, network: 'evm' }

        const { message } = await AuthApi.requestMessage(userData.address, userData.chain.toString(), "evm");

        const signature = await signMessageAsync({message});

        const { user } = await AuthApi.verifyMessage(message, signature, "evm");

        console.log("user", user)

        localStorage.setItem("SessionToken", user.sessionToken);

        return user;
    }

    const LoginWithCoinbase = async() => {
        if (isConnected) {
            await disconnectAsync();
        }

        const { account, chain } = await connectAsync({
            connector: new CoinbaseWalletConnector({
                options: {
                    appName: 'iraz.app',
                },
            }),
        })

        const userData = { address: account, chain: chain.id, network: 'evm' }

        const { message } = await AuthApi.requestMessage(userData.address, userData.chain.toString(), "evm");

        const signature = await signMessageAsync({message});

        const { user } = await AuthApi.verifyMessage(message, signature, "evm");

        console.log("user", user)

        localStorage.setItem("SessionToken", user.sessionToken);

        return user;
    }

    const handleAuth = async (provider:any, callback: VoidFunction, stopPending: VoidFunction) => {
        try {

            var user;

            switch(provider){
                case "coinbase":
                    user = await LoginWithCoinbase();
                    break;
                case "walletconnect":
                    user = await LoginWithWalletConnect();
                    break;
                default:
                    user = await LoginWithMetamask();
                    break;
            }
            
            setUser(user);
            setIsAuthenticated(true);

            callback();
        } catch (error) {
            stopPending();
            console.log(error)
        } finally {
            
        }
    };

    const SetLightMode = () => {
        localStorage.setItem("theme", "light");
        setDarkMode(false);
        setTheme(LightTheme);
        SaveTheme("light");
    }
    const SetDarkMode = () => {
        localStorage.setItem("theme", "dark");
        setDarkMode(true);
        setTheme(DarkTheme);
        SaveTheme("dark");
    }

    async function MarkNotificationsSeen(){
        try{
            if(isAuthenticated){
                await NotificationApi.markAllAsSeen();

                FetchNotifications();
            }
        } catch(error){
            console.log("Mark notifs as seen error", error);
        }
    }

    function SeenNotifications(){
        MarkNotificationsSeen();
    }

    async function FetchNotifications(){
        if(isAuthenticated){
            try{
                const notificationRes = await NotificationApi.getAll();
                console.log(notificationRes)
                setNotifications(notificationRes);
            } catch(error){
                console.log("Fetch notif error", error);
            }
        }
        
    }

    function RefreshNotifications(){
        FetchNotifications();
    }
    
    useEffect(() => {
        async function CheckPremium(){
            if(user){
                const response = await iRazPremiumAdapter.IsPremium(user.ethAddress);
                setIsPremium(response);
            }
        }

        if(user && user.ethAddress){
            CheckPremium();
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [user])

    async function SaveTheme(theme: string){
        if(user){
            try{
                await UserApi.setTheme(theme);
            } catch(error){
                console.log("Error setting user theme: " + error);
            }
        }
    }

    useEffect(() => {
        async function CheckAdmin(){
            if(user){
                const response = await iRazAdminAdapter.IsAdmin(user.ethAddress);
                setIsAdmin(response);
            }
        }

        async function CheckTheme() {
            if(localStorage.getItem("SessionToken")){
                const userData = await UserApi.whoAmI();
                setUser(userData.user);
                setIsAuthenticated(true)

                const notificationRes = await NotificationApi.getAll();
                setNotifications(notificationRes);

                if(userData.user.theme === "dark"){
                    SetDarkMode();
                } else if (userData.user.theme === "light"){
                    SetLightMode();
                }
            } else {
                const theme = localStorage.getItem("theme");
                if(theme === "light"){
                    SetLightMode();
                } else if(theme === "dark") {
                    SetDarkMode();
                }
            }
        }

        async function FetchStats(){
            try{
                const stats = await StatsApi.getStats();
                setSiteStats(stats.data);

                // get the eth/usd exchange
                const data = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD');
                setUsd(data.data.USD);

                setStatsLoaded(true);
            } catch(error){

            }
        }

        CheckAdmin();
        CheckTheme();
        FetchStats();
    }, [])

    function logout(){
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem("SessionToken");
    }

    return(
        <AppContext.Provider
            value={{
                isAuthenticated,
                setIsAuthenticated,
                user,
                isAdmin,
                isPremium,
                Theme,
                SetDarkMode,
                SetLightMode,
                darkMode,
                notifications,
                SeenNotifications,
                RefreshNotifications,
                breakpoint,
                showConnect,
                openConnect,
                closeConnect,
                siteStats,
                usd,
                statsLoaded,
                handleAuth,
                logout
            }}>
            {children}
        </AppContext.Provider>
    )
}