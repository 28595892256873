import { parseUnits } from "ethers";
import { Raffle } from "../interfaces/raffle";
import { SortOrder } from "../interfaces/types";

export const SortRaffles = (sortOrder: SortOrder, raffles: Raffle[]) => {
    switch(sortOrder){
        case "price_low_high":
            return raffles.sort((a,b) => {
                const aPrice = parseUnits(a.entryPrice, "wei");
                const bPrice = parseUnits(b.entryPrice, "wei");
                if(aPrice < bPrice) return -1;
                if(aPrice > bPrice) return 1;
                return 0;
            });
        case "price_high_low":
            return raffles.sort((a,b) => {
                const aPrice = parseUnits(a.entryPrice, "wei");
                const bPrice = parseUnits(b.entryPrice, "wei");
                if(aPrice < bPrice) return 1;
                if(aPrice > bPrice) return -1;
                return 0;
            });
        case "token_id_low_high":
            return raffles.sort((a,b) => a.tokenId - b.tokenId);
        case "token_id_high_low":
            return raffles.sort((a, b) => b.tokenId - a.tokenId);
        case "total_spots_low_high": 
            return raffles.sort((a, b) => a.spots - b.spots);
        case "total_spots_high_low":
            return raffles.sort((a, b) => b.spots - a.spots);
        case "available_spots_low_high":
            return raffles.sort((a, b) => (a.spots - a.entryCount) - (b.spots - b.entryCount));
        case "available_spots_high_low":
            return raffles.sort((a, b) => (b.spots - b.entryCount) - (a.spots - a.entryCount));
        case "time_oldest_first":
            return raffles.sort((a,b) => a.blockStamp - b.blockStamp);
        case "time_newest_first":
            return raffles.sort((a,b) => b.blockStamp - a.blockStamp);
        default:
            return raffles.sort((a,b) => b.blockStamp - a.blockStamp);
    }
}