import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, alpha, styled } from "@mui/material";

interface RaffleFloorPriceBadgeProps {
    floorPrice?: number;
    topLeft?: boolean;
}

const FloorPrice = styled(Typography)({
    position: "absolute",
    width: "fit-content",
    fontWeight: 500,
    background: alpha("#4b006e", 0.6),
    right: 5,
    bottom: 8,
    padding: "2px 5px",
    border: "2px solid #4b006e",
    borderRadius: "5px",
    svg: {
        marginLeft: 3
    }
});

const TopLeftFloorPrice = styled(Typography)({
    position: "absolute",
    width: "fit-content",
    fontWeight: 500,
    background: alpha("#4b006e", 0.6),
    top: 5,
    left: 5,
    padding: "2px 5px",
    border: "2px solid #4b006e",
    borderRadius: "5px",
    svg: {
        marginLeft: 3
    }
});

const RaffleFloorPriceBadge = ({floorPrice, topLeft = false}: RaffleFloorPriceBadgeProps) => {

    if(!floorPrice){
        return <></>;
    }

    return (
        topLeft ? <TopLeftFloorPrice sx={{fontSize: {xs: 12, sm: 10, xl: 12, xxl: 14}}}>
            FP: {floorPrice} <FontAwesomeIcon icon={faEthereum} />
        </TopLeftFloorPrice> :
        <FloorPrice sx={{fontSize: {xs: 12, sm: 10, xl: 12, xxl: 14}}}>
            FP: {floorPrice} <FontAwesomeIcon icon={faEthereum} />
        </FloorPrice>
    )
}

export default RaffleFloorPriceBadge;