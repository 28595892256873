import { Raffle } from "../interfaces/raffle";
import { axios } from "./configs/axios";

const ENDPOINT = "/raffles";

const mostRecent = async () => {
    const { data } = await axios.get(`${ENDPOINT}/recent`);

    return data.raffles as Raffle[];
};

const getById = async (id:string, userEthAddress?: string) => {
    const { data } = await axios.get(`${ENDPOINT}/${id}?userEthAddress=${userEthAddress}`);

    return {raffle: data.raffle as Raffle, watching: data.watching};
}

const watch = async(id:string) => {
    const { data } = await axios.put(`${ENDPOINT}/${id}/watch`);

    return data;
}

const refreshNft = async(id: string) => {
    const { data } = await axios.put(`${ENDPOINT}/${id}/refresh-nft`);

    return data;
}

const RaffleApi = {
    mostRecent,
    getById,
    watch,
    refreshNft
};

export default RaffleApi;
