import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import CollectionCard from "../components/CollectionCard";
import { Collection } from "../interfaces/collection";
import CollectionApi from "../api/collection";
import useDocumentTitle from "../hooks/useDocumentTitle";

const Explore = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [collections, setCollections] = useState<Collection[]>([]);
    //const [pagesLoaded, setPagesLoaded] = useState<number[]>([]);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    //const [loaded, setLoaded] = useState<number[]>([]);
    const loaded:number[] = useMemo(() => {
        return []
    }, []);

    useDocumentTitle("Explore | iRaz");

    useEffect(() => {
        async function fetchPage(page:number){
            try{
                if (!loaded.includes(page)) {
                    console.log(page)
                    loaded.push(page);
                    //setLoaded(oldValues => [...oldValues, page]);
                    const pageData = await CollectionApi.getPage(page);
                    setCollections((oldData) => [...oldData, ...pageData.collections]);
                    setHasNextPage(pageData.hasNextPage);
                }
                
            } catch(error){
    
            }
        }

        fetchPage(currentPage);
    }, [currentPage, loaded])

    function LoadNextPage(){
        setCurrentPage(oldPage => oldPage+=1);
    }

    return (
        <Grid container justifyContent="center" alignItems="start">
            <Grid item xs={10} mt={8} mb={3}>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    Explore Collections
                </Typography>
            </Grid>
            <Grid
                item
                xs={10}
                display="flex"
                flexWrap="wrap"
                justifyContent="space-evenly"
                rowGap={8}
                mt={4}
                minHeight="80vh"
            >
                {collections.length > 0 ?
                collections.map((i) => {
                    return (
                        <Box maxWidth={330}>
                            <CollectionCard collection={i} />
                        </Box>
                    );
                })
                : <Skeleton width={200} height={400}/> }
            </Grid>
            {hasNextPage &&
            <Grid item xs={10} mt={4}>
                <Button variant="contained" size="medium" onClick={LoadNextPage}>Load More</Button>
            </Grid>
            }
        </Grid>
    );
}

export default Explore;