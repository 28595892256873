import { ProfileUser } from "../interfaces/user";
import {axios} from "./configs/axios";

const ENDPOINT = "/users";

const getUserByAddress = async (address: string) => {
    const { data } = await axios.get(`${ENDPOINT}/${address}`);

    return data.user as ProfileUser;
};

const checkUsername = async (username: string) => {
    const {data} = await axios.get(`${ENDPOINT}/check-username?username=${username}`);

    return data;
}

const setTheme = async (theme: string) => {
    const {data} = await axios.put(`${ENDPOINT}/theme`,
    {
        theme
    });

    return data;
}

const setSocial = async (website: string, username: string) => {
    const {data} = await axios.put(`${ENDPOINT}/social`,
    {
        website,
        username
    });

    return data;
}

const whoAmI = async() => {
    const { data } = await axios.get(`${ENDPOINT}/whoAmI`);

    return data;
}

const update = async(username: string, about: string) => {
    const { data } = await axios.put(`${ENDPOINT}`, {username, about});

    return data;
}

const UserApi = {
    getUserByAddress,
    checkUsername,
    setTheme,
    whoAmI,
    setSocial,
    update
};

export default UserApi;