import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { AppContext } from "../context/AppContext";
import { Autocomplete, Badge, Drawer, lighten, Switch, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { AccountCircle, Bedtime, LightMode, Notifications, NotificationsNone, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SearchApi from "../api/search";
import { SearchResult } from "../interfaces/search";
import ConnectWallet from "./modals/ConnectWallet";
import iRazLogo from "../images/iRaz_Primary.png";
import NotificationItem from "./NotificationItem";
import SearchBar from "./nav/SearchBar";

const settings = ["Profile", "Logout"];

const NavMenu = () => {
    const {user, isAuthenticated, logout} = useContext(AppContext);
    const {Theme, darkMode, SetDarkMode, SetLightMode, notifications, SeenNotifications, showConnect, openConnect, closeConnect, siteStats, usd, statsLoaded} = useContext(AppContext);
    const navigate = useNavigate();

    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const toggleDrawer = () => setOpenDrawer(oldValue => !oldValue);

     const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
         null
     );
     const [anchorElNotif, setAnchorElNotif] = React.useState<null | HTMLElement>(
        null
     );

     const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        if(isAuthenticated) setAnchorElUser(event.currentTarget);
     };
     const handleOpenNotifMenu = (event: React.MouseEvent<HTMLElement>) => {
        if(isAuthenticated) setAnchorElNotif(event.currentTarget);
     };

     const handleCloseUserMenu = (setting?:string) => {
        console.log(setting);
        switch(setting){
            case "Logout":
                logout();
                break;
            case "Profile":
                GoToProfile(user?.ethAddress as string);
                break;
        }
        setAnchorElUser(null);
     };

     const handleCloseNotifMenu = () => {
        setAnchorElNotif(null);
        SeenNotifications();
     };

     const handleNotifMenuClick = (raffleId: number | undefined) => {
        if(raffleId){
            window.scrollTo(0, 0);
            navigate(`/raffle/${raffleId}`);
        }
        handleCloseNotifMenu();
     }

    function GoToProfile(address: string){
        window.scrollTo(0, 0);
        navigate(`/user/${address}`);
    }

    function GoToExplore(){
        setOpenDrawer(false);
        window.scrollTo(0, 0);
        navigate("/explore");
    }

    function GoHome(){
        window.scrollTo(0, 0);
        navigate("/");
    }

    useEffect(() => {
        if(isAuthenticated && user){
            localStorage.setItem("SessionToken", user.sessionToken);
        } else {
            //localStorage.removeItem("SessionToken");
        }
    }, [isAuthenticated, user])

    const SearchItems = async (query: string | null) => {
        if(query){
            setSearchQuery(query);
        } else {
            setSearchResults([]);
            setSearchQuery("");
        }
    }

    useEffect(() => {
        async function GetData(){
            const {searchData} = await SearchApi.search(searchQuery);

            setSearchResults(searchData);
        }

        if(searchQuery !== ""){
            const timer = setTimeout(() => {
                GetData();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [searchQuery])

    function HandleLogin(){
        setOpenDrawer(false);
        if(!isAuthenticated){
            openConnect();
        } else {
            GoToProfile(user?.ethAddress as string);
        }
    } 

    function GetNotifications(){
        if(notifications.length > 0){
            return notifications.map((notification) => (
                <MenuItem
                    key={notification._id}
                    onClick={() => handleNotifMenuClick(notification.activity?.raffleId)}
                >
                    <NotificationItem notification={notification} />
                </MenuItem>
            ))
        }

        return <Box px={2} py={1}>
            <Typography>No notifications.</Typography>
        </Box>
    }

     return (
        <>
        <ConnectWallet
            open={showConnect}
            close={closeConnect}
        />
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={toggleDrawer}>
                <Typography variant="h5" px={8} py={2} >Navigation</Typography>
                <MenuItem sx={{borderTop: 1, borderBottom: 1, borderColor: "lightgray"}} onClick={GoToExplore}>
                    <Typography variant="h6">EXPLORE</Typography>
                </MenuItem>
                <MenuItem sx={{borderTop: 1, borderBottom: 1, borderColor: "lightgray"}} onClick={HandleLogin}>
                    <Typography variant="h6">LIST</Typography>
                </MenuItem>

                <SearchBar />

                <Box display="flex" mx="auto" mt={1}>
                    <Bedtime sx={{my: "auto"}} />
                    <Switch sx={{my: "auto"}} color="secondary" checked={!darkMode} onClick={() => darkMode ? SetLightMode() : SetDarkMode()} />
                    <LightMode sx={{color: "yellow", my: "auto"}} />
                </Box>
        </Drawer>
        <AppBar position="static" sx={{backgroundColor: Theme.palette.background.default}}>
            <Container maxWidth="md" color="primary" sx={{display: "flex", justifyContent: "space-around", my: 1}}>
                {statsLoaded ?
                <>
                <Typography fontSize={12}>Volume total: <span style={{color: darkMode ? lighten(Theme.palette.primary.main, 0.7) : Theme.palette.primary.main}}>{siteStats.totalVolume} ETH</span></Typography>
                <Typography fontSize={12} display={{xs: "none", sm: "block"}}>Volume 24h: <span style={{color: darkMode ? lighten(Theme.palette.primary.main, 0.7) : Theme.palette.primary.main}}>{siteStats.totalVolume24Hr} ETH</span></Typography>
                <Typography fontSize={12}>ETH/USD: <span style={{color: darkMode ? lighten(Theme.palette.primary.main, 0.7) : Theme.palette.primary.main}}>${usd.toLocaleString()}</span></Typography>
                <Typography fontSize={12}>Raffles total: <span style={{color: darkMode ? lighten(Theme.palette.primary.main, 0.7) : Theme.palette.primary.main}}>{siteStats.raffleCount}</span></Typography>
                <Typography fontSize={12} display={{xs: "none", sm: "block"}}>Raffles 24h: <span style={{color: darkMode ? lighten(Theme.palette.primary.main, 0.7) : Theme.palette.primary.main}}>{siteStats.raffleCount24Hr}</span></Typography>
                </>
                :
                <Typography fontSize={12}>&nbsp;</Typography>}
            </Container>
        </AppBar>
         <AppBar position="static" sx={{backgroundColor: Theme.palette.primary.main}}>
             <Container maxWidth="xl" color="primary">
                 <Toolbar disableGutters>

                     <Box flexGrow={0} my="auto" onClick={() => GoHome()} sx={{height: "60px"}}>
                        <img src={iRazLogo} alt="" height="90%" style={{marginTop: "3px"}} />
                     </Box>

                     <Box marginLeft={8} padding={2} paddingRight={8}
                         sx={{
                             flexGrow: 1,
                             display: { xs: "none", md: "flex" },
                         }}
                     >
                        <SearchBar />
                     </Box>

                     <Box sx={{ flexGrow: {xs: 1, md: 0}, display: "flex", justifyContent: {xs: "end", md: "space-between"} }}>
                        <Button
                            sx={{
                                my: 2,
                                color: "white",
                                display: {xs: "none", md: "block"},
                                fontSize: "16px",
                            }}
                            onClick={GoToExplore}
                        >
                            Explore
                        </Button>
                        <Button
                            sx={{
                                my: 2,
                                color: "white",
                                display: {xs: "none", md: "block"},
                                fontSize: "16px",
                                paddingLeft: "30px",
                                paddingRight: "30px",
                                marginLeft: "20px",
                                marginRight: "20px"
                            }}
                            onClick={HandleLogin}
                        >
                            List
                        </Button>

                        {isAuthenticated &&
                        <Tooltip title="Open settings">
                            <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{height: "fit-content", my: "auto"}}
                            >
                                {isAuthenticated ?
                                <Avatar
                                    alt={user?.username as string}
                                    sx={{height: "35px", width: "35px"}}
                                    src="/static/images/avatar/2.jpg"
                                />
                                : <AccountCircle sx={{fontSize: "35px"}}/>}
                            </IconButton>
                        </Tooltip>
                        }
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={() => handleCloseUserMenu()}
                        >
                            {settings.map((setting) => (
                                <MenuItem
                                    key={setting}
                                    onClick={() => handleCloseUserMenu(setting)}
                                >
                                    <Typography textAlign="center">
                                        {setting}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>

                        <Tooltip title="Go to Wallet">
                            <IconButton
                                onClick={HandleLogin}
                                sx={{height: "fit-content", my: "auto"}}
                            >
                                <AccountBalanceWalletIcon sx={{fontSize: "35px"}} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "55px" }}
                            id="menu-appbar"
                            anchorEl={anchorElNotif}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElNotif)}
                            onClose={() => handleCloseNotifMenu()}
                            title="Notifications"
                        >
                            {GetNotifications()}
                        </Menu>

                        {isAuthenticated &&
                        <IconButton sx={{height: "fit-content", my: "auto"}} onClick={handleOpenNotifMenu}>
                            {notifications.filter((i) => !i.hasUserSeen).length > 0 ?
                            <Badge badgeContent={notifications.length} color="primary">
                                <Notifications sx={{fontSize: "35px"}} /> 
                            </Badge>:
                            <NotificationsNone sx={{fontSize: "35px"}} />}
                        </IconButton>
                        }

                        <Box sx={{display: {xs: "none", md: "flex"}}} ml={4}>
                            <Bedtime sx={{my: "auto"}} />
                            <Switch sx={{my: "auto"}} color="secondary" checked={!darkMode} onClick={() => darkMode ? SetLightMode() : SetDarkMode()} />
                            <LightMode sx={{color: "yellow", my: "auto"}} />
                        </Box>
                     </Box>

                     <Box flexGrow={0} textAlign="right" display={{xs: "block", md: "none"}}>
                        <IconButton onClick={toggleDrawer}>
                            <MenuIcon />
                        </IconButton>
                     </Box>
                 </Toolbar>
             </Container>
         </AppBar>
         </>
     );
};

export default NavMenu;
