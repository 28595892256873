import { useNavigate, useParams } from "react-router-dom";
import {useEffect} from "react";

const RaffleRedirect = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(id){
            navigate(`/raffle/${id}`);
        }
    }, [id]);

    return <></>
}

export default RaffleRedirect;