import { CheckCircleOutline, Error, OpenInNew } from "@mui/icons-material";
import { Box, Button, CircularProgress, Modal, Typography } from "@mui/material";
import { useState } from "react";
import iRazAdapter from "../../adapters/iRazAdapter";
import { Raffle } from "../../interfaces/raffle";
import { GetTransactionUrl } from "../../utils/getTransactionUrl";
import Styles from "../../themes/styles";

interface CancelRaffleProps {
    item: Raffle | null;
    open: boolean;
    close: () => void;
    callback: () => void;
}

const CancelRaffle = ({ item, open, close, callback }: CancelRaffleProps) => {
    const { ModalStyle } = Styles();

    enum Steps {
        CONFIRMATION = 1,
        CONFIRM_WINDOW = 2,
        COMPLETED = 3,
        ERROR = 4
    }

    const [currentStep, setCurrentStep] = useState<Steps>(Steps.CONFIRMATION);
    const [transactionHash, setTransactionHash] = useState<string>("");

    function CloseModal() {
        callback();
        setCurrentStep(Steps.CONFIRMATION);
        setTransactionHash("");
        close();
    }

    function ViewTransaction(){
        var url = GetTransactionUrl(transactionHash);
        window.open(url, "_blank");
    }

    async function Cancel(){
        setCurrentStep(Steps.CONFIRM_WINDOW);
        try{
            if(item){
                const success = await iRazAdapter.CancelRaffle(item.raffleId);
                if(success){
                    setCurrentStep(Steps.COMPLETED);
                    setTransactionHash(success.hash);
                    setTimeout(() => {
                        CloseModal();
                    }, 5000)
                }
            } else {
                setCurrentStep(Steps.ERROR);
            }
            
        } catch(error:any){
            if (error.code !== "ACTION_REJECTED"){
                setCurrentStep(Steps.ERROR)
            } else {
                setCurrentStep(Steps.CONFIRMATION)
            }
        }
    }

    if (item) {
        return (
            <Modal open={open} onClose={CloseModal}>
                <Box borderRadius="25px" sx={ModalStyle}>
                    <Box
                        borderBottom={1}
                        borderColor="darkgrey"
                        textAlign="center"
                    >
                        <Typography
                            variant="h4"
                            sx={{ fontSize: "40px", fontWeight: 600 }}
                            marginBottom={2}
                        >
                            Cancel Raffle
                        </Typography>
                    </Box>
                    <Box
                        borderBottom={1}
                        paddingTop={1}
                        paddingBottom={1}
                        borderColor="darkgrey"
                        display="flex"
                    >
                        <img
                            src={item.image}
                            style={{
                                maxWidth: "80px",
                                marginRight: "10px",
                                borderRadius: "10px",
                            }}
                            alt=""
                        />
                        <div
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                            <Typography
                                sx={{ fontWeight: 600, fontSize: "22px" }}
                            >
                                {item.name}
                            </Typography>
                            <Typography
                                sx={{ fontWeight: 500, fontSize: "18px" }}
                            >
                                {item.collectionData.name}
                            </Typography>
                        </div>
                    </Box>
                    <Box borderBottom={1} borderColor="darkgrey" textAlign="center" py={2}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "20px",
                            }}
                        >
                            {currentStep === Steps.CONFIRM_WINDOW &&
                            <>
                                <CircularProgress color="primary" size={50}/><br />
                                Confirm the transaction in your browser
                            </>
                            }
                            {currentStep === Steps.CONFIRMATION && "Press the button below to cancel this Raffle and refund the entries."}
                            {currentStep === Steps.COMPLETED && 
                            <>
                                <CheckCircleOutline sx={{fontSize: "70px"}} color="primary"/><br />
                                You have successfully cancelled and refunded the entries for this Raffle! <br />
                                <Box
                                    display="flex"
                                    onClick={() => ViewTransaction()}
                                    sx={{ cursor: "pointer" }}
                                    justifyContent="center"
                                    mt={1}
                                >
                                    <Typography sx={{ textDecoration: "underline" }}>
                                        View Transaction
                                    </Typography>
                                    <OpenInNew sx={{ my: "auto" }} />
                                </Box>
                            </>}
                            {currentStep === Steps.ERROR &&
                            <>
                                <Error sx={{fontSize: "70px"}} color="error"/><br />
                                An error has occurred cancelling the Raffle
                            </>}
                        </Typography>
                    </Box>
                    {currentStep === Steps.CONFIRMATION &&
                    <Box textAlign="center" mt={2}>
                        <Button 
                            variant="contained" 
                            color="error"
                            size="medium" sx={{fontSize: "20px"}}
                            onClick={Cancel}
                        >
                            Cancel Raffle
                        </Button>
                    </Box>
                    }
                </Box>
            </Modal>
        );
    } else return <></>;
};

export default CancelRaffle;