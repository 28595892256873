import { authApi } from "./configs/axios";

const ENDPOINT = "/auth";

const requestMessage = async (account: string, chain: string, networkType: 'evm' | 'sol') => {
    const { data } = await authApi.post(`${ENDPOINT}/request-message`, {
        address: account.toLowerCase(),
        chain,
        networkType
    });

    return data;
};

const verifyMessage = async (message: string, signature: string, networkType: string) => {
    const { data } = await authApi.post(`${ENDPOINT}/sign-message`, {
        message,
        signature,
        networkType
    });

    return data;
};

const AuthApi = {
    requestMessage,
    verifyMessage
};

export default AuthApi;
