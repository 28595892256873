import { EvmNft } from "@moralisweb3/evm-utils";
import { NFTCollection } from "../interfaces/collection";
import { Raffle } from "../interfaces/raffle";

export const getRaffleCollectionFilters = (raffles:Raffle[]) => {
    const collections = raffles.map((i) => {
        return {
            name: i.collectionData.name,
            contract: i.collectionData.nftContract,
        } as NFTCollection;
    });

    const unique = collections.filter((value, index, self) => {
        return self.findIndex((i) => i.contract === value.contract) === index;
    });

    return unique;
}

export const getNftCollectionFilters = (nfts: EvmNft[]) => {
    const collections = nfts.map((i) => {
        return {
            name: i.name || i.tokenAddress.toString(),
            contract: i.tokenAddress.toString(),
        } as NFTCollection;
    });

    const unique = collections.filter((value, index, self) => {
        return self.findIndex((i) => i.contract === value.contract) === index;
    });

    return unique;
}