import { Box } from "@mui/system";
import TabPanel from "../TabPanel";
import {EvmNft} from "@moralisweb3/evm-utils";
import NFTCard from "../NftCard";
import { Button } from "@mui/material";
import { useMemo, useState } from "react";

interface NftTabProps {
    currentTab: number;
    nfts: EvmNft[];
    removeNftFilter: (contract: string) => void;
    nftFilters: {contract: string, name: string}[];
    cardSize: number;
    searchQuery: string;
    isUser: boolean;
    raffleItem: (item:EvmNft) => void;
}

const NftTab = ({currentTab, nfts, removeNftFilter, nftFilters, cardSize = 330, searchQuery, isUser, raffleItem}:NftTabProps) => {
    const [amountToLoad, setAmountToLoad] = useState<number>(10);
    const [filteredLength, setFilteredLength] = useState<number>(0);

    const FilteredNfts = useMemo(() => {
        function SearchItems(element:EvmNft, index: number, array: EvmNft[]){
            if(element.name){
                if(element.name?.toLowerCase().includes(searchQuery.toLowerCase())) return true;
            }
            if(element.tokenId.toString().includes(searchQuery.toLowerCase())) return true;
            if(element.metadata){
                const meta = element.metadata as any;
                if(meta.name?.toLowerCase().includes(searchQuery.toLowerCase())) return true;
            }
            return false;
        }

        var filtered = nfts;
        if(nftFilters.length > 0){
            const nftAddresses = nftFilters.map(i => i.contract);
            filtered = nfts.filter(i => nftAddresses.includes(i.tokenAddress.toString()))
        }

        if(searchQuery !== ""){
            filtered = filtered.filter(SearchItems);
        }

        setFilteredLength(filtered.length);

        if(filtered.length > amountToLoad){
            filtered = filtered.slice(0, amountToLoad);
        }

        return filtered;
    }, [nftFilters, nfts, searchQuery, amountToLoad])

    function LoadMore(){
        setAmountToLoad(oldValue => oldValue += 10);
    }

    return (
        <TabPanel value={currentTab} index={0}>
            {nftFilters.length > 0 && (
            <Box marginLeft={2} marginRight={2} display="flex" flexWrap="wrap">
                {nftFilters.map(i => {
                    return <Button variant="contained" color="error" onClick={() => removeNftFilter(i.contract)} sx={{marginRight: "5px"}}>
                        {i.name}
                    </Button>
                })}
            </Box>
            )}
            <Box display="flex" justifyContent={{xs: "center", md: "start"}} flexWrap={"wrap"} width="100%">
                {FilteredNfts.map((i: EvmNft) => {
                    return (
                        <Box margin={2} minWidth={cardSize} maxWidth={cardSize}>
                            <NFTCard nft={i} isUser={isUser} raffleItem={raffleItem} />
                        </Box>
                    );
                })}
            </Box>
            {filteredLength > amountToLoad && <Button variant="contained" size="medium" onClick={LoadMore} sx={{mt: 3}}>Load More</Button>}
        </TabPanel>
    );
}

export default NftTab;