import {useContext, useMemo} from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { AppContext } from './context/AppContext';
import NavMenu from './components/NavMenu';
import Profile from './pages/Profile';
import Explore from './pages/Explore';
import ViewRaffle from './pages/ViewRaffle';
import CollectionPage from './pages/CollectionPage';
import Footer from './components/Footer';
import Unauthorized from './pages/Unauthorized';
import Admin from './pages/Admin';
import RaffleRedirect from './RaffleRedirect';
import { ToastContainer } from 'react-toastify';

function App() {
const {Theme, isAdmin, isAuthenticated, darkMode} = useContext(AppContext);

const AdminPage:JSX.Element = useMemo(() => isAdmin && isAuthenticated ? <Admin /> : <Unauthorized />, [isAdmin, isAuthenticated])

  return (
      <div className="App" style={{backgroundColor: Theme.palette.background.default}}>
            <ThemeProvider theme={Theme}>   
                <NavMenu />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/user/:address" element={<Profile />} />
                    <Route path="/explore" element={<Explore />} />
                    <Route path="/raffle/:id" element={<ViewRaffle />} />
                    <Route path="/collection/:address" element={<CollectionPage />} />
                    <Route path="/admin" element={AdminPage} />
                    <Route path="/r/:id" element={<RaffleRedirect />} />
                </Routes>
                <Footer />
            </ThemeProvider>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                rtl={false}
                draggable
                pauseOnHover
                theme={darkMode ? "dark" : "light"}
            />
      </div>
  );
}

export default App;
