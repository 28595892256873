import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Typography } from "@mui/material";
import { useMemo } from "react";

interface CollectionFloorPriceProps {
    floorPrice?: number;
}

const CollectionFloorPrice = ({ floorPrice }:CollectionFloorPriceProps) => {
    const FloorPriceLabel = useMemo(() => {
        return <>
            FP: {floorPrice} <FontAwesomeIcon icon={faEthereum} />
        </>
    }, [floorPrice])
    
    if(!floorPrice){
        return <></>;
    }

    return (
        <Chip variant="filled" color="primary" label={FloorPriceLabel} sx={{borderRadius: "10px"}} />
    )
}

export default CollectionFloorPrice;